import React, { memo, useState, useEffect,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BublleChart from "../Graphique/BubbleChart";
import AdeoLogo from "../../assets/images/adeo.png";
import Select from "react-select";
import ReactToPrint from "react-to-print";

const ResultatDomaine = memo(() => {
  const params = useParams();
  const componentRef = useRef();
  
  const [campIsOk, setCampIsOk] = useState(false);
  const [limited, setLimited] = useState(true);
  const date = new Date().getFullYear();
  const [domaine, setDomaine] = useState();
  const [critere, setCritere] = useState([]);
  const [planActions, setPlanActions] = useState([]);
  const [listeCampagne, setListeCampagne] = useState();
  const [campagne, setCampagne] = useState([]);
  const [campagneError, setCampagneError] = useState("");
  const [taux_de_participation, setTaux_de_participation] = useState();
  const [moyenneRoti, setMoyenneRoti] = useState();
  const [moyenne, setMoyenne] = useState();
  const [divisionTxParticipation, setDivisionTxParticipation] = useState();
  const [moyenne_des_moyenne, setMoyenne_des_moyenne] = useState();
  const [isMounted, setIsMounted] = useState(false);

  const calculerMoyenne = (resultats) => {
    const moyennes = {};
    

    resultats.forEach((resultat) => {
      const { id_critere_boussole, libelle, question_1, question_2 } = resultat;

      if (!moyennes[id_critere_boussole]) {
        moyennes[id_critere_boussole] = {
          libelle: libelle,
          q1: [],
          q2: [],
        };
      }

      moyennes[id_critere_boussole].q1.push(question_1);
      moyennes[id_critere_boussole].q2.push(question_2);
    });

    const moyennesFinales = [];

    for (const critere in moyennes) {
      const { libelle, q1, q2 } = moyennes[critere];

      const moyenneQ1 =
        q1.reduce((somme, value) => somme + value, 0) / q1.length;
       
      const moyenneQ2 =
        q2.reduce((somme, value) => somme + value, 0) / q2.length;

      

      moyennesFinales.push({
        critere: parseInt(critere), // Convertir l'identifiant du critère en nombre si nécessaire
        libelle,
        moyenneQ1: parseFloat(moyenneQ1.toFixed(2)),
        moyenneQ2: parseFloat(moyenneQ2.toFixed(2)),
      });
    }

    

    return moyennesFinales;
  };
  const Moyenne_Des_Moyenne = (moyenne) => {
    let sommeQ1 = 0;
    let sommeQ2 = 0;
    let denominateur = 0;

    for (let i = 0; i < moyenne.length; i++) {
      const { moyenneQ1, moyenneQ2 } = moyenne[i];

      sommeQ1 += moyenneQ1;
      sommeQ2 += moyenneQ2;
      denominateur++;
    }

    const moyenne_des_moyenne_Q1 =
      denominateur > 0 ? sommeQ1 / denominateur : 0;
    const moyenne_des_moyenne_Q2 =
      denominateur > 0 ? sommeQ2 / denominateur : 0;

    return {
      moyenne_des_moyenne_Q1: moyenne_des_moyenne_Q1.toFixed(2),
      moyenne_des_moyenne_Q2: moyenne_des_moyenne_Q2.toFixed(2),
    };
  };

  const FetchCritere = async () => {
    const body_2 = {
      id_societe: params.idSociete,
    };
    const getByIdOptions_2 = {
      method: "POST",
      body: JSON.stringify(body_2),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(
      "/api/criteresBoussole/getAllCriteresBoussole",
      getByIdOptions_2
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setCritere(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Campagne = async () => {
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des profils
    await fetch("/api/campagneBoussole/getCampagne", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let objCampagne = data.map((campagne) => {
          return { value: campagne.id_campagne, label: campagne.nom };
        });

        setListeCampagne(objCampagne);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChangeCampagne = (event) => {
    setCampagne(event);
  };

  const Validate = () => {
    //validation profil
    if (!campagne) {
      setCampagneError("La campagne doit être renseigné");
    }
  };

  const FetchCommentaire = async () => {
    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify({ idParent: params.id, idCampagne: campagne.value }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(
      "/api/commentaireBoussole/getCommentaireByDomaine",
      getByIdOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
      console.log(Moyenne_roti(data))
       
        setMoyenneRoti(Moyenne_roti(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const FetchResultat = async () => {
    const body = {
      idParent: params.id,
      idCampagne: campagne.value,
    };

    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    //Récupération des equipes
    await fetch("/api/equipes/getResultatDomaine", getByIdOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        

        setMoyenne(calculerMoyenne(data));
        setMoyenne_des_moyenne(Moyenne_Des_Moyenne(calculerMoyenne(data)));

        TauxDeParticipation(data);
      })
      .catch((error) => {
        console.log(error);
      });

    const body_2 = {
      idEquipe: params.id,
    };

    const getByIdOptions_2 = {
      method: "POST",
      body: JSON.stringify(body_2),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/equipes/getEquipeById", getByIdOptions_2)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDomaine(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const FetchPlanAction = async () => {
    const requestOptions_2 = {
      method: "POST",
      body: JSON.stringify({ idCampagne: campagne.value, idParent: params.id }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch("/api/planEquipe/getPlanByCampagne", requestOptions_2)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setPlanActions(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const FectchPlanAndResultat = () => {
    setCampIsOk(true);
    FetchResultat();
    FetchPlanAction();
    FetchCommentaire();
  };

  const TauxDeParticipation = (tab) => {
    let uniqueIndividus = new Set();
    let nominateur = 0;
    let denominateur = 0;
    let taux;
    let division;

    for (let i = 0; i < tab.length; i++) {
        const idIndividu = tab[i].id_boussole_individu;

        // Vérifier si l'individu a déjà été comptabilisé
        if (!uniqueIndividus.has(idIndividu)) {
            uniqueIndividus.add(idIndividu);
            denominateur++;
            
            // Vérifier si le statut est "Terminé"
            if (tab[i].statut === "Terminé") {
                nominateur++;
            }
        }
    }

    taux = `${nominateur}/${denominateur}`;
    division = nominateur / denominateur;

    setTaux_de_participation(taux);
    setDivisionTxParticipation(division);
    return;
};
  const Moyenne_roti = (roti) => {
    let moyenneRoti;
    let tab = [];
    let somme;
    let denominateur = roti.length;

    if (roti.length > 0) {
      for (let i = 0; i < roti.length; i++) {
        tab.push(roti[i].roti);
      }
      somme = tab.reduce((somme, value) => (somme = somme + value));

      moyenneRoti = somme / denominateur;
    } else {
      moyenneRoti = "Pas de données";
    }
    return moyenneRoti.toFixed(2);
  };

  useEffect(() => {
    setIsMounted(true) ;
    
    if (Validate) {
      Campagne();
    }
    FetchCritere();
    FetchResultat();
    FetchPlanAction();
    FetchCommentaire();
  }, []);
 

  if ( isMounted ===false || moyenne === undefined || planActions === undefined || domaine === undefined){
    return (
      <>
        <h1 className="text-center mt-20 text-3xl">Loading</h1>
      </>
    );
  } else {
    return (
      <div ref={componentRef}>
        <div className="flex justify-center">
          <img src={AdeoLogo} alt="" className="h-24 " />
        </div>
        <div className="flex flex-col items-center justify-center h-full mb-2 mt-5">
          <h1 className="text-white text-xl text-center mt-1 p-2 mb-2 bg-bleu-incubate w-96 mx-auto">
            {domaine.LIBELLE_EQUIPE} {date} 
          </h1>
        </div>
        <div className=" p-1 flex-auto justify-center mb-5 ">
          <p className="my-4  text-lg text-center leading-relaxed text-slate-500">
            Merci de choisir une campagne
          </p>
          <div className="flex justify-center">
            <Select
              className="w-64"
              aria-labelledby="Campagne-label"
              inputId="Campagne-input"
              name="Campagne"
              options={listeCampagne}
              onChange={handleChangeCampagne}
            />
          </div>

          {campagneError ? (
            <div className="text-red-600">{campagneError}</div>
          ) : null}
          <div className="text-center">
            <button
              className=" bg-orange-incubate text-white   text-sm px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none  mb-1    mt-2"
              type="button"
              onClick={FectchPlanAndResultat}>
              Valider
            </button>
          </div>
        </div>
        <div className=" flex justify-center mt-5 mb-5 space-x-5">
          <p className="mr-2">
            Taux de participation :{" "}
            {divisionTxParticipation >= 1 ? (
              <span className="bg-green-400 rounded-sm p-1 ml-1">
                {taux_de_participation}
              </span>
            ) : divisionTxParticipation < 1 &&
              divisionTxParticipation >= 0.5 ? (
              <span className="bg-orange-400 rounded-sm p-1 ml-1 ">
                {taux_de_participation}
              </span>
            ) : (
              <span className="bg-red-400 rounded-sm p-1 ml-1 ">
                {taux_de_participation}
              </span>
            )}
          </p>
          <p>
            Roti:{" "}
            {moyenneRoti >= 3 ? (
              <span className="bg-green-400 rounded-sm p-1 ml-1">
                {moyenneRoti}
              </span>
            ) : moyenneRoti < 3 && moyenneRoti > 2 ? (
              <span className="bg-orange-400  rounded-sm p-1 ml-1">
                {moyenneRoti}
              </span>
            ) : (
              <span className="bg-red-400  rounded-sm p-1 ml-1">
                {moyenneRoti}
              </span>
            )}
          </p>
        </div>
        <div className=" flex  space-x-20 p-1  justify-center ">
          <table className="items-center bg-transparent w-1/3 border-collapse  ">
            <thead>
              <tr>
                <td className=" bg-orange-100 text-bleu-incubate align-middle border border-solid border-bleu-incubate p-3 text-xs uppercase  border-r-0 whitespace-nowrap  text-left">
                  Criteres
                </td>
                <td className="bg-orange-100 text-bleu-incubate align-middle border border-solid border-bleu-incubate p-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap text-center ">
                  FF
                </td>
                <td className=" bg-orange-100 text-bleu-incubate align-middle border border-solid border-bleu-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap text-center  ">
                  OR
                </td>
              </tr>
            </thead>

            <tbody>
              {moyenne.map((moyenne) => (
                <tr className="border  border-bleu-incubate">
                  <td className="text-left border border-bleu-incubate ">
                    {moyenne.libelle}
                  </td>
                  {moyenne.moyenneQ1 >= 3 ? (
                    <td className="text-center bg-green-300">
                      {moyenne.moyenneQ1}
                    </td>
                  ) : moyenne.moyenneQ1 >= -2.99 &&
                    moyenne.moyenneQ1 <= 2.99 ? (
                    <td className="text-center bg-orange-200">
                      {moyenne.moyenneQ1}
                    </td>
                  ) : (
                    <td className="text-center bg-red-300">
                      {moyenne.moyenneQ1}
                    </td>
                  )}
                  {moyenne.moyenneQ2 >= 3 ? (
                    <td className="text-center bg-green-300">
                      {moyenne.moyenneQ2}
                    </td>
                  ) : moyenne.moyenneQ2 >= -2.99 &&
                    moyenne.moyenneQ2 <= 2.99 ? (
                    <td className="text-center bg-orange-200">
                      {moyenne.moyenneQ2}
                    </td>
                  ) : (
                    <td className="text-center bg-red-300">
                      {moyenne.moyenneQ2}
                    </td>
                  )}
                </tr>
              ))}

              <tr className="border border-bleu-incubate">
                <td className="text-center font-bold border bg-yellow-100 border-bleu-incubate">
                  Note
                </td>
                {moyenne_des_moyenne.moyenne_des_moyenne_Q1 >= 3 ? (
                  <td className="text-center bg-emerald-400 font-bold">
                    {moyenne_des_moyenne.moyenne_des_moyenne_Q1}
                  </td>
                ) : moyenne_des_moyenne.moyenne_des_moyenne_Q1 >= -2.99 &&
                  moyenne_des_moyenne.moyenne_des_moyenne_Q1 <= 2.99 ? (
                  <td className="text-center bg-orange-400 font-bold border border-bleu-incubate">
                    {moyenne_des_moyenne.moyenne_des_moyenne_Q1}
                  </td>
                ) : (
                  <td className="text-center bg-red-400 font-bold border border-bleu-incubate">
                    {moyenne_des_moyenne.moyenne_des_moyenne_Q1}
                  </td>
                )}
                {moyenne_des_moyenne.moyenne_des_moyenne_Q2 >= 3 ? (
                  <td className="text-center bg-emerald-400 font-bold border border-bleu-incubate">
                    {moyenne_des_moyenne.moyenne_des_moyenne_Q2}
                  </td>
                ) : moyenne_des_moyenne.moyenne_des_moyenne_Q2 >= -2.99 &&
                  moyenne_des_moyenne.moyenne_des_moyenne_Q2 <= 2.99 ? (
                  <td className="text-center bg-orange-400 font-bold border border-bleu-incubate">
                    {moyenne_des_moyenne.moyenne_des_moyenne_Q2}
                  </td>
                ) : (
                  <td className="text-center bg-red-400 font-bold border border-bleu-incubate">
                    {moyenne_des_moyenne.moyenne_des_moyenne_Q2}
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          <div className="w-1/3">
            <BublleChart data={moyenne} />
          </div>
        </div>
     

        <div className=" flex  space-x-20 p-1  justify-center mb-5  ">
          <div className="w-9/12">
            <h1 className="text-orange-incubate text-2xl text-center mt-5">
              Plan d'action
            </h1>
            <div className="flex justify-center mt-5">
              <table className="items-center bg-transparent  border-collapse  ">
                <thead>
                  <tr>
                    <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase   whitespace-nowrap  text-left">
                      Equipe
                    </td>
                    <td className="  bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase border-l-0  whitespace-nowrap text-center ">
                      Objectif
                    </td>
                    <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                      Action
                    </td>
                    <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                      Lead ?
                    </td>
                    <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                      Date
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {planActions.length > 0
                    ? planActions.map((planAction, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0
                              ? "bg-stone-100"
                              : "bg-orange-100 rounded-md"
                          }>
                          <td className="p-1">{planAction.LIBELLE_EQUIPE}</td>
                          <td className="p-1">{planAction.objectif}</td>
                          <td className="p-1 whitespace-pre-line">
                            {planAction.action}
                          </td>
                          <td className="p-1">{planAction.lead}</td>
                          <td className="p-1">{planAction.date_creation}</td>
                        </tr>
                      ))
                    : "Vous n'avez pas encore de plan d'action"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center rounded-lg shadow-lg border mx-auto mt-3 mb-3 text-orange-incubate border-orange-incubate hover:bg-orange-incubate hover:text-white w-32 p-2">
              <ReactToPrint
                trigger={() => <button>Exporter</button>}
                content={() => componentRef.current}
              />
            </div>
      </div>
    );
  }
});

export default ResultatDomaine;
