import React, { memo } from "react";

const Alert = memo((props) => {
  const data = props.message;
  const couleur = props.couleur;

  return couleur === "grenn" ? (
    <div
      class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-gray-800 dark:text-green-400"
      role="alert"
    >
      <span class="font-medium">{data}</span>
    </div>
  ) : (
    <div
      class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <span class="font-medium">{data}</span>
    </div>
  );
});

export default Alert;
