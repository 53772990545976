import React, {Component} from 'react'
import { withRouter } from '../Outils/withRouter';
import { BiRightArrow } from 'react-icons/bi';
import { Navigate } from "react-router-dom";
import Logo from '../../assets/images/LogoBleu.png';


class Login extends Component {
  constructor(props){
    super(props);
    //Variables utilisé pour la page
    
    this.state = {
      mail : '',
      password : '',
      mailError : '',
      passwordError : '',
      auth : false,
      loading : true
    };
  }

  //Fonction de detection d'une modification dans un champs
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name] : value
    });
  }

  //Fonction de validation
  validate = () => {
    let mailError = '';
    let passwordError = '';

    //Validation mail
    if(!this.state.mail.includes('@')){
      mailError = "Adresse mail non valide"
    }

    //Validation mot de passe
    if(!this.state.password){
      passwordError = "Veuillez saisir votre mot de passe"
    }

    //Retour d'erreur
    if( mailError || passwordError ){
      this.setState({mailError, passwordError});
      return false;   
    }else{
      this.setState({mailError, passwordError});
      return true;
    }
  }

  //Fonction d'apel api pour la connexion
  onClick = (event) => {
    event.preventDefault();

    if(this.validate()){
      const body = {
        mail : this.state.mail,
        password : this.state.password
      }

      const requestOptions = {
        method : 'POST', 
        body : JSON.stringify(body),
        headers : {
          'Content-Type' : 'application/json'
        }
      };
  
      fetch('/api/access/login', requestOptions)
        .then( res => {
          if(res.status === 200) {
            this.props.navigate('/dashboard');
          }else {
            if(res.status === 401) {
              alert("Mail ou mot de passe incorrect ...");
              throw new Error(res.error);
            }else if(res.status === 500){
              alert("Erreur interne ... Veuillez ressayer ...");
              throw new Error(res.error);
            }
          }
        }).catch(err => {
          console.error(err);
        });
    }
  }

  //Fonctiond e chargement d'information au chargement de la page 
  componentDidMount(){
    const requestOptions = {
        method : 'GET',
        header : {
            'Content-Type' : 'application:json'
        }
    };
    fetch ('/api/checkToken', requestOptions)
        .then(res => {
            if(res.status === 200 || res.status === 304){
                this.setState({ auth : true, loading : false});
            }else{
                this.setState({ auth : false, loading : false});
            }
        });
}

//Fonction de rendu
  render(){
    if (this.state.loading){
      return <h1> </h1>
    }else{
      return this.state.auth ? <Navigate to="/dashboard"/> : 
        <div className=' flex flex-row justify-center h-full '>
          <div className='lg:w-3/5  flex flex-col h-screen '>
          
          <div className='h-1/3'>
            <div className='lg:hidden flex justify-center mt-5' >
                <img src={Logo} alt='Logo ' className='w-96'/>
              </div>

              <div className=' flex flex-col  lg:text-lg mt-5 pt-5 lg:pt-16 ' >
                <h2 className='flex justify-center '>Bonjour, et bienvenue sur l’appli Boussole ! </h2>
                <h2 className='flex justify-center'>Merci de vous connecter à votre compte. </h2>
              </div>
              
            </div>

            <div className = 'flex lg:mx-40  flex-col   justify-center h-screen' >
              
              <div className=' flex p-2 lg:p-5 mb-4 border-2 border-l-4 border-l-orange-incubate flex-col'>
                <label htmlFor='mail' className='block  md:text-lg font-semibold mb-2'>Mail </label>
                  <input type='email' name='mail' placeholder='Adresse mail' value={this.state.mail} onChange={this.handleInputChange} className='shadow appearance-none  w-full py-1 px-3 text-orange-incubate leading-tight focus:outline-none focus:shadow-outline mb-2'/>
                { this.state.mailError ? <div className='text-red-600'>{ this.state.mailError }</div> : null }
              </div>
              <div className=' flex p-2 lg:p-5 mb-4 border-2 border-l-4 border-l-orange-incubate flex-col'>
                <label htmlFor='password' className='block md:text-lg font-semibold mb-2'>Mot de passe </label>
                  <input type='password' name='password' placeholder='Mot de passe' value={this.state.password} onChange={this.handleInputChange} className='shadow appearance-none  w-full py-1 px-3 text-orange-incubate leading-tight focus:outline-none focus:shadow-outline mb-2'/>
                  { this.state.passwordError ? <div className='text-red-600'>{ this.state.passwordError }</div> : null }
              </div>
              <div className='flex justify-center mt-5'>
                <button type='button' onClick={this.onClick} className="text-white py-2 px-5 mx-2 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate"><div className="flex flex-row items-center"><p className='px-2 '>Connexion</p><BiRightArrow  className='pt-1'/></div></button>
              </div><div className=' flex justify-center items-end h-1/3 ' >
              <img src='/images/IC_logo_couleurs_orange_nom_cote.png' alt='Logo Be Shake' className=' h-1/2'/>
            </div>
            </div>

            
            
            
          </div>

          <div className=' lg:flex h-screen hidden justify-center md:w-2/5 bg-bleu-incubate-2'>
            <div className=' flex  items-center' >
              <img src={Logo} alt='Logo Be Shake' className='bg-bleu-incubate-2 w-96'/>
            </div>
          </div>
        </div>
      
    }
  }

}

//exports
export default withRouter(Login);
