import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { GrGroup } from "react-icons/gr";
import { Link } from "react-router-dom";
import { AiOutlineCompass } from "react-icons/ai";
import { MdManageAccounts } from "react-icons/md";
import AdeoLogo from "../../assets/images/adeo.png";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    //Variable utilisé dans la page
    this.state = {
      loading: true,
      isAdmin: false,
    };
  }

  //Fonction de redirections vers les différentes pages
  redirectSocietePage = (event) => {
    event.preventDefault();
    this.props.navigate("/societes");
  };

  redirectUsersPage = (event) => {
    event.preventDefault();
    this.props.navigate("/users");
  };

  redirectMesPulseurs = (event) => {
    event.preventDefault();
    this.props.navigate("/mesPulseurs");
  };
  redirectMesBoussole = (event) => {
    event.preventDefault();
    this.props.navigate("/mesBoussoles");
  };

  //Fonction s'éxécutant au chargement de la page pour récupérer les informations nécéssaire
  componentDidMount() {
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    fetch("/api/isAdmin", requestOptions).then((res) => {
      if (res.status === 200 || res.status === 304) {
        this.setState({ isAdmin: true, loading: false });
      } else {
        this.setState({ isAdmin: false, loading: false });
      }
    });

    fetch("/api/register/me", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          pseudo: data.PSEUDO,
          societte: data.LIBELLE_SOCIETE,
          equipe: data.LIBELLE_EQUIPE,
          loadingIndivi: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Fonction de rendu
  render() {
    if (this.state.loading) {
      return <h1>Loading </h1>;
    } else {
      if (this.state.isAdmin) {
        return (
          <>
            <h1 className="text-orange-incubate xl:text-3xl xl:mb-20 xl:flex xl:justify-center flex justify-center sm:text-xl sm:text-center sm:mb-10 ">
              Bienvenue sur votre espace Boussole
            </h1>

            <div className="flex justify-center ">
            <img src={AdeoLogo} alt="" className="h-24 mb-5" />
          </div>
            <div className="xl:flex xl:flex-row xl:items-center xl:justify-center xl:space-x-20 sm:flex-col sm:justify-center sm:text-sm sm:space-x-5 ">
              {/* <div className="bg-white border rounded border-orange-incubate xl:p-6 shadow-2xl sm:p-3 sm:w-72">
                <h1 className="text-orange-incubate xl:text-xl flex items-center justify-center mb-5 sm:text-sm ">
                  Mes Informations
                </h1>
                <div className=" flex items-center justify-center  ">
                  <div className="grid   gap-4 grid-cols-1 text-left ">
                    <p>
                      <span className="font-bold mr-2 ">Nom:</span>
                      {this.state.nom}
                    </p>
                    <p>
                      <span className="font-bold mr-2">Prenom:</span>
                      {this.state.prenom}
                    </p>
                    <p>
                      <span className="font-bold mr-2">Mail:</span>
                      {this.state.mail}
                    </p>
                    <p>
                      <span className="font-bold mr-2">Societe:</span>
                      {this.state.societte != null
                        ? this.state.societte
                        : "non renseigner"}
                    </p>
                  </div>
                </div>
                <div className="mt-2 border rounded-xl bg-orange-incubate text-center p-2 text-white sm:text-sm">
                  <Link to="/editUser">Modifier mon profil </Link>
                </div>
              </div> */}

              <div className="flex flex-col items-center justify-center sm:mt-3 ">
                <div className="items-center justify-center">
                  <div className="grid sm:gap-2 sm:grid-cols-2  gap-4 lg:grid-cols-2 ">
                    {/* <div className=" flex justify-center shadow-md rounded-lg p-4 hover:-translate-1 hover:scale-90 duration-300">
                      <button
                        onClick={this.redirectMesPulseurs}
                        className=" sm:text-sm xl:text-lg py-2 px-5 flex  hover:border-gray-200/50 ">
                        <RiHeartPulseFill size="1.7em" color="red" />
                        <p className="pl-2">Mes Pulseurs</p>
                      </button>
                    </div> */}

                    <div className="shadow-md rounded-lg p-4 hover:-translate-1 hover:scale-90 duration-300">
                      <button
                        onClick={this.redirectMesBoussole}
                        className="sm:text-sm  xl:text-lg py-2 px-5 flex  hover:border-gray-200/50 ">
                        <AiOutlineCompass size="1.7em" color="blue" />
                        <p className="pl-2">Mes Boussoles</p>
                      </button>
                    </div>
                    <div className="shadow-md rounded-lg p-4 hover:-translate-1 hover:scale-90 duration-300">
                      <button
                        onClick={this.redirectSocietePage}
                        className="sm:text-sm xl:text-lg py-2 px-5 flex  hover:border-gray-200/50  ">
                        <MdManageAccounts size="1.7em" color="black" />
                        <p className="pl-2">Gérer les sociétés</p>
                      </button>
                    </div>
                    <div className="shadow-md rounded-lg p-4 hover:-translate-1 hover:scale-90 duration-300">
                      <button
                        onClick={this.redirectUsersPage}
                        className="sm:text-sm xl:text-lg py-2 px-5 flex  hover:border-gray-200/50 ">
                        <GrGroup size="1.7em" color="orange" />
                        <p className="pl-2">Gérer les utilisateurs</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <h1 className="text-orange-incubate text-3xl mb-20 flex justify-center">
              Bienvenue sur votre espace {this.state.prenom}
            </h1>
            <div className="flex flex-row items-center justify-center space-x-20 ">
              <div className="bg-white border rounded border-orange-incubate p-6 shadow-2xl ">
                <h1 className="text-orange-incubate text-xl flex items-center justify-center mb-5 ">
                  Mes Informations
                </h1>
                <div className=" flex items-center justify-center  ">
                  <div className="grid   gap-4 grid-cols-1 text-left ">
                    <p>
                      <span className="font-bold mr-2">Pseudo:</span>
                      {this.state.pseudo}
                    </p>
                    <p>
                      <span className="font-bold mr-2">Equipe:</span>
                      {this.state.equipe}
                    </p>
                    <p>
                      <span className="font-bold mr-2">Société:</span>
                      {this.state.societte != null
                        ? this.state.societte
                        : "non renseigner"}
                    </p>
                  </div>
                </div>
                <div className="mt-2 border rounded-xl bg-orange-incubate text-center p-2 text-white">
                  <Link to="/editUser">Modifier mon profil </Link>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center h-full ">
                <div className="items-center justify-center">
                  <div className="grid sm:gap-2 sm:grid-cols-2  gap-4 lg:grid-cols-2 ">

                    <div className="shadow-md rounded-lg p-4 hover:-translate-1 hover:scale-90 duration-300">
                      <button
                        onClick={this.redirectMesBoussole}
                        className="sm:text-sm  xl:text-lg py-2 px-5 flex  hover:border-gray-200/50 ">
                        <AiOutlineCompass size="1.7em" color="blue" />
                        <p className="pl-2">Mes Boussoles</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  }
}

//Export
export default withRouter(Dashboard);
