import React, { memo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LogoAdeo from "../../assets/images/adeo.png";

const EditPlanAction = memo(() => {
  const [planAction, setPlanAction] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const [objectif, setObjectif] = useState("");
  const [action, setAction] = useState("");
  const [lead, setLead] = useState("");
  const [libelleError, setLibelleError] = useState("");

  // Fetch PlanAction data
  const FetchPlanAction = async () => {
    const body = { id_plan: params.id };

    const get = {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch("/api/planEquipe/getPlanActionById", get);
      const data = await response.json();
      setAction(data.action);
      setLead(data.lead);
      setObjectif(data.objectif);
      setPlanAction(data);
    } catch (error) {
      console.log(error);
    }
  };

  // Input change handlers
  const handleInputChangeObjectif = (event) => setObjectif(event.target.value);
  const handleInputChangeAction = (event) => setAction(event.target.value);
  const handleInputChangeLead = (event) => setLead(event.target.value);

  // Validation function
  const validate = () => {
    let error = "";

    if (!objectif) {
      error = "Sélectionner une réponse";
      alert("Veuillez entrer un objectif, s'il vous plaît");
    } else if (!action) {
      error = "Sélectionner une réponse";
      alert("Veuillez entrer une action, s'il vous plaît");
    } else if (!lead) {
      error = "Sélectionner une réponse";
      alert("Veuillez entrer un Lead, s'il vous plaît");
    }

    if (error) {
      setLibelleError(error);
      return false;
    } else {
      setLibelleError("");
      return true;
    }
  };

  // Update PlanAction data
  const UpdatePLanAction = async () => {
    if (validate()) {
      const body = {
        id_plan: params.id,
        objectif: objectif,
        action: action,
        lead: lead,
      };

      

      const getByIdOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      };

      try {
        const res = await fetch("/api/planEquipe/modify", getByIdOptions);
        if (res.status === 200) {
          alert("Le plan d'action a été mis à jour avec succès !");
          navigate(`/resultatBoussole/${planAction.id_boussole}`);
        } else {
          alert("Veuillez remplir tous les champs, s'il vous plaît");
          throw new Error(res.statusText);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    FetchPlanAction();
  }, [params.id]);

  return (
    <>
      <div className="flex justify-center">
        <img src={LogoAdeo} alt="logoAdeo" className="w-40 h-20 mt-5" />
      </div>
      <div className="text-center">
        <h2 className="text-xl text-orange-incubate mb-3">
          Ajouter le plan d'action
        </h2>
      </div>

      <div className="flex justify-center space-x-10 mt-10">
        <div className="flex-col">
          <h1 className="text-center text-orange-incubate">Objectif</h1>
          <div className="flex justify-center">
            <div className="mb-4 border border-gray-200 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="bg-white rounded-t-lg dark:bg-gray-800">
                <textarea
                  onChange={handleInputChangeObjectif}
                  rows="10"
                  className="w-full px-4 py-2 text-sm text-gray-900 bg-white dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer les objectifs ..."
                  value={objectif}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col">
          <h1 className="text-center text-orange-incubate">Action</h1>
          <div className="flex justify-center">
            <div className="mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="bg-white rounded-t-lg dark:bg-gray-800">
                <textarea
                  onChange={handleInputChangeAction}
                  rows="10"
                  className="w-full px-4 py-2 text-sm text-gray-900 bg-white dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer les actions ..."
                  value={action}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col">
          <h1 className="text-center text-orange-incubate">Lead</h1>
          <div className="flex justify-center">
            <div className="mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="bg-white rounded-t-lg dark:bg-gray-800">
                <textarea
                  onChange={handleInputChangeLead}
                  rows="10"
                  className="w-full px-4 py-2 text-sm text-gray-900 bg-white dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer le Lead ..."
                  value={lead}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={UpdatePLanAction}
          className="border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg text-white mt-4"
        >
          Valider
        </button>
      </div>
    </>
  );
});

export default EditPlanAction;
