import React, { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const AmbassadeurBoussole = memo(() => {
  const params = useParams();
  const navigate = useNavigate();

  const [nomAmbassadeur, setNomAmbassadeur] = useState();
  const [listeAmbassadeurs, setListeAmbassadeurs] = useState([]);
  const [libelleError, setLibelleError] = useState();
  const handleInputChangeAmbassadeur = (event) => {
    setNomAmbassadeur(event.target.value);
  };

  const validate = () => {
    let Error = "";

    //Validation libelle
    if (!nomAmbassadeur) {
      alert("veuillez entrer un ambassadeur svp");
    }
    //Retour d'erreur

    if (Error) {
      setLibelleError(Error);
      return false;
    } else {
      setLibelleError(Error);
      return true;
    }
  };

  const RegisterAmbassadeur = () => {
    if (validate()) {
      const body = {
        idBoussole: params.id,
        nom: nomAmbassadeur,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/ambassadeurBoussole/", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            alert("L'amabassadeur a été rajouté avec succès !");
            navigate("/resultatBoussole/" + params.id);
          } else {
            alert("il y a déjà 2 ambassadeurs pour cette campagne");
            navigate("/resultatBoussole/" + params.id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <div className=" flex-col justify-center">
        <h2 className="flex justify-center mb-3 text-xl text-orange-incubate">
          Veuillez selectionner l'ambassadeur
        </h2>
        <div className="flex justify-center">
          <div className="w-1/3 mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
            <div className="  bg-white rounded-t-lg dark:bg-gray-800 ">
              <input
                type="text"
                onChange={handleInputChangeAmbassadeur}
                rows="15
                    "
                className="w-full px-4 py-2  text-sm text-gray-900 bg-white  dark:bg-gray-800  dark:text-white dark:placeholder-gray-400"
                placeholder="Merci d'entrer la synthese ...."
                required></input>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={RegisterAmbassadeur}
          className="border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg  text-white mt-4">
          Valider
        </button>
      </div>
    </>
  );
});

export default AmbassadeurBoussole;
