import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import BublleChart from "../Graphique/BubbleChart";
import Table from "../Table/Table.jsx";
import AdeoLogo from "../../assets/images/adeo.png";
import Commentaire from "../Commentaire/Commentaire.jsx";
import PlanAction from "./../ResultatDomaine/PlanAction";
import Synthese from "./../SyntheseBoussole/Synthese";
import PlanActionEquipe from "../PlanActionEquipe/PlanActionEquipe";
import ReactToPrint from "react-to-print";

class ResultatBoussole extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      //Champs du formulaire

      idBoussole: this.props.params.id,
      idEquipe: " ",
      idCritere: [],
      moyenne: [],
      moyenne_N1: [],
      moyenne_N2: [],
      moyenne_des_moyenne_1: 0,
      moyenne_des_moyenne_2: 0,
      moyenne_des_moyenne_1_N1: 0,
      moyenne_des_moyenne_2_N1: 0,
      moyenne_des_moyenne_1_N2: 0,
      moyenne_des_moyenne_2_N2: 0,
      isAdmin: false,
      showModal: false,
      buttonCommentaire: false,
      division_tauxParticipation: 0,
      synthese_boussole: {},

      //Erreur de champs
      loadingCritere: true,
      date: new Date().getFullYear(),
    };
  }
  afficherCom = () => {
    return this.state.buttonCommentaire
      ? this.setState({ buttonCommentaire: false })
      : this.setState({ buttonCommentaire: true });
  };

  Synthese = () => {
    this.props.navigate(`/syntheseBoussole/${this.props.params.id}`);
  };
  PlanAction = () => {
    const { idEquipe, idBoussole } = this.state;
    this.props.navigate(
      `/registerPlanAction/${idEquipe}?autreParam=${idBoussole}`
    );
  };
  Ambassadeur = () => {
    this.props.navigate(`/ambassadeurBoussole/${this.props.params.id}`);
  };

  calculerMoyenne = (resultats) => {
    const moyennes = {};

    resultats.forEach((resultat) => {
      const { id_critere_boussole, libelle, question_1, question_2 } = resultat;

      if (!moyennes[id_critere_boussole]) {
        moyennes[id_critere_boussole] = {
          libelle: libelle,
          q1: [],
          q2: [],
        };
      }

      moyennes[id_critere_boussole].q1.push(question_1);
      moyennes[id_critere_boussole].q2.push(question_2);
    });

    const moyennesFinales = [];

    for (const critere in moyennes) {
      const { libelle, q1, q2 } = moyennes[critere];

      const moyenneQ1 =
        q1.reduce((somme, value) => somme + value, 0) / q1.length;
      const moyenneQ2 =
        q2.reduce((somme, value) => somme + value, 0) / q2.length;

      moyennesFinales.push({
        critere: parseInt(critere), // Convertir l'identifiant du critère en nombre si nécessaire
        libelle,
        moyenneQ1,
        moyenneQ2,
      });
    }

    this.setState({ moyenne: moyennesFinales });

    return moyennesFinales;
  };

  calculerMoyenne_N1 = (resultatN1) => {
    const moyennes = {};

    resultatN1.forEach((resultat) => {
      const { id_critere_boussole, question_1, question_2 } = resultat;

      if (!moyennes[id_critere_boussole]) {
        moyennes[id_critere_boussole] = {
          q1: [],
          q2: [],
        };
      }

      moyennes[id_critere_boussole].q1.push(question_1);
      moyennes[id_critere_boussole].q2.push(question_2);
    });

    const moyennesFinales = [];

    for (const critere in moyennes) {
      const { q1, q2 } = moyennes[critere];

      const moyenneQ1 =
        q1.reduce((somme, value) => somme + value, 0) / q1.length;
      const moyenneQ2 =
        q2.reduce((somme, value) => somme + value, 0) / q2.length;

      moyennesFinales[`moyenne_critere_${critere}_Q1`] = moyenneQ1;
      moyennesFinales[`moyenne_critere_${critere}_Q2`] = moyenneQ2;
    }

    this.setState({ moyenne_N1: moyennesFinales });

    return moyennesFinales;
  };

  calculerMoyenne_N2 = (resultatN2) => {
    const moyennes = {};

    resultatN2.forEach((resultat) => {
      const { id_critere_boussole, question_1, question_2 } = resultat;

      if (!moyennes[id_critere_boussole]) {
        moyennes[id_critere_boussole] = {
          q1: [],
          q2: [],
        };
      }

      moyennes[id_critere_boussole].q1.push(question_1);
      moyennes[id_critere_boussole].q2.push(question_2);
    });

    const moyennesFinales = {};

    for (const critere in moyennes) {
      const { q1, q2 } = moyennes[critere];

      const moyenneQ1 =
        q1.reduce((somme, value) => somme + value, 0) / q1.length;
      const moyenneQ2 =
        q2.reduce((somme, value) => somme + value, 0) / q2.length;

      moyennesFinales[`moyenne_critere_${critere}_Q1`] = moyenneQ1;
      moyennesFinales[`moyenne_critere_${critere}_Q2`] = moyenneQ2;
    }

    this.setState({ moyenne_N2: moyennesFinales });

    return moyennesFinales;
  };

  Moyenne_Des_Moyenne = (moyenne) => {
    let sommeQ1 = 0;
    let sommeQ2 = 0;
    let denominateur = 0;

    for (let i = 0; i < moyenne.length; i++) {
      const { moyenneQ1, moyenneQ2 } = moyenne[i];

      sommeQ1 += moyenneQ1;
      sommeQ2 += moyenneQ2;
      denominateur++;
    }

    const moyenne_des_moyenne_Q1 =
      denominateur > 0 ? sommeQ1 / denominateur : 0;
    const moyenne_des_moyenne_Q2 =
      denominateur > 0 ? sommeQ2 / denominateur : 0;

    this.setState({
      moyenne_des_moyenne_Q1: moyenne_des_moyenne_Q1,
      moyenne_des_moyenne_Q2: moyenne_des_moyenne_Q2,
    });
  };

  Moyenne_roti = (roti) => {
    let moyenneRoti;
    let tab = [];
    let somme;
    let denominateur = roti.length;

    if (roti.length > 0) {
      for (let i = 0; i < roti.length; i++) {
        tab.push(roti[i].roti);
      }
      somme = tab.reduce((somme, value) => (somme = somme + value));

      moyenneRoti = somme / denominateur;
    } else {
      moyenneRoti = "Pas de données";
    }
    this.setState({
      moyenneRoti: moyenneRoti.toFixed(2),
    });

    return;
  };
  TauxDeParticipation = (tab) => {
    let denominateur = tab.length;
    let nominateur = 0;
    let taux;
    let division;

    for (let i = 0; i < tab.length; i++) {
      if (tab[i].statut === "Terminé") {
        nominateur = nominateur + 1;
      }
    }
    taux = `${nominateur}/${denominateur}`;
    division = nominateur / denominateur;

    this.setState({
      taux_de_participation: taux,
      division_tauxParticipation: division,
    });

    return;
  };
  FetchAmbassadeurBoussole = async () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ idBoussole: this.state.idBoussole }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch(
      `/api/ambassadeurBoussole/getAmbassadeurByBoussole`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          ambassadeurBoussole: data,
        });
      });
  };

  //Fonction au chargement de la page pour récupérer les informations d'affichage
  async componentDidMount() {
    await this.FetchAmbassadeurBoussole();
    //Récupération de id Equipe
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };
    await fetch("/api/isAdmin", requestOptions).then((res) => {
      if (res.status === 200 || res.status === 304) {
        this.setState({ isAdmin: true });
      } else {
        this.setState({ isAdmin: false });
      }
    });

    const bodyIdBousolle = {
      idBoussole: this.props.params.id,
    };

    const get = {
      method: "POST",
      body: JSON.stringify(bodyIdBousolle),
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (this.state.isAdmin) {
      await fetch("/api/commentaireBoussole/getCommentaireByBoussole", get)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.setState({
            dataCommentaire: data,
          });
          this.Moyenne_roti(data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    const get3 = {
      method: "POST",
      body: JSON.stringify(bodyIdBousolle),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/boussole/getBoussoleIndividuByBoussole", get3)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        this.TauxDeParticipation(data);
      })
      .catch((error) => {
        console.log(error);
      });

    await fetch("/api/criteresBoussole/getCritereByBoussole", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          this.state.idCritere.push(data[i].ID_CRITERE);
        }
        this.setState({
          criteres: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    await fetch("/api/equipes/getEquipeByBoussole", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          idEquipe: data.ID_EQUIPE,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const bodyIdEquipe = {
      idEquipe: this.state.idEquipe,
    };

    const get_2 = {
      method: "POST",
      body: JSON.stringify(bodyIdEquipe),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch("/api/equipes/getEquipeById", get_2)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          nomEquipe: data.LIBELLE_EQUIPE,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const body = {
      idBoussole: this.props.params.id,
      idEquipe: this.state.idEquipe,
    };

    const getResultatByBoussole = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/boussole/getResultatByBoussole", getResultatByBoussole)
      .then((res) => {
        if (res.status === 401) {
          this.setState({ Acces: true });
        }

        return res.json();
      })
      .then((data) => {
        this.setState({
          resultat: data.resultat,
          resultatN1: data.resultatN1,
          resultatN2: data.resultatN2,
        });

        this.calculerMoyenne(data.resultat);
        this.calculerMoyenne_N1(data.resultatN1);
        this.calculerMoyenne_N2(data.resultatN2);
        this.Moyenne_Des_Moyenne(this.state.moyenne);
        this.setState({
          loadingCritere: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    await fetch("/api/syntheseBoussole/getSyntheseByBoussole", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          synthese_boussole: data,
          loadingSynthese: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Fonction de rendu

  render() {
    if (this.state.Acces === true) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          Unauthorized
        </h1>
      );
    } else if (this.state.loadingCritere) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          loading
        </h1>
      );
    } else if (this.state.resultatN2.length > 0) {
      return (
        <>
          <div ref={this.componentRef}>
            <div className="flex justify-center">
              <img src={AdeoLogo} alt="" className="h-24 " />
            </div>
            <div className="flex flex-col items-center justify-center h-full ">
              <h1 className="text-orange-incubate text-3xl">
                Resultat Boussole {this.state.date}
              </h1>
            </div>
            <div className="flex justify-center text-center   text-md  mt-1 p-2 mb-2 items-center space-x-5   ">
              <p className="mr-2">
                Taux de participation :{" "}
                {this.state.division_tauxParticipation >= 1 ? (
                  <span className="bg-green-400 rounded-sm p-1 ml-1">
                    {this.state.taux_de_participation}
                  </span>
                ) : this.state.division_tauxParticipation < 1 &&
                  this.state.division_tauxParticipation >= 0.5 ? (
                  <span className="bg-orange-400 rounded-sm p-1 ml-1 ">
                    {this.state.taux_de_participation}
                  </span>
                ) : (
                  <span className="bg-red-400 rounded-sm p-1 ml-1 ">
                    {this.state.taux_de_participation}
                  </span>
                )}
              </p>
              {this.state.isAdmin ? (
                <p>
                  ROTI :
                  {this.state.moyenneRoti >= 3 ? (
                    <span className="bg-green-400 rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  ) : this.state.moyenneRoti < 3 &&
                    this.state.moyenneRoti > 2 ? (
                    <span className="bg-orange-400  rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  ) : (
                    <span className="bg-red-400  rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  )}
                </p>
              ) : null}
              <p className=" p-1">Ambassadeur :</p>
              {this.state.ambassadeurBoussole.length > 0 ? (
                this.state.ambassadeurBoussole.map((e) =>
                  e.nom === null ? (
                    <>
                      <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                        Pas d'ambassadeur, veuillez les définir svp
                      </p>
                    </>
                  ) : (
                    <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                      {e.nom}
                    </p>
                  )
                )
              ) : (
                <>
                  <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                    Pas d'ambassadeur, veuillez les définir svp
                  </p>
                </>
              )}
            </div>

            <div className="">
              <h1 className="text-white text-xl text-center mt-1 p-2 mb-2 bg-bleu-incubate w-96 mx-auto">
                {this.state.nomEquipe}
              </h1>
              <div className=" flex  space-x-20 p-1  justify-center ">
                <Table
                  moyenne={this.state.moyenne}
                  moyenne_des_moyenne_1={this.state.moyenne_des_moyenne_Q1.toFixed(
                    2
                  )}
                  moyenne_des_moyenne_2={this.state.moyenne_des_moyenne_Q2.toFixed(
                    2
                  )}
                />
                <div className="w-1/3">
                  <BublleChart data={this.state.moyenne} />
                </div>
              </div>
              <div className="flex justify-center space-x-20 p-1 items-start ">
                {this.state.synthese_boussole.synthese === "" ||
                Object.keys(this.state.synthese_boussole).length === 0 ? (
                  <p className="text-center text-xl mt-2">
                    Merci de rédiger la synthèse
                  </p>
                ) : (
                  <div className="border border-gray-900 bg-slate-50 rounded w-1/3  p-3 mt-2">
                    <Synthese data={this.state.synthese_boussole} isAdmin={this.state.isAdmin}/>
                  </div>
                )}
                <div className="w-1/3">
                  <PlanActionEquipe idBoussole={this.state.idBoussole} isAdmin={this.state.isAdmin} />
                </div>
              </div>
            </div>
            <div className="mt-5 flex justify-center  items-center">
              {this.state.isAdmin ? (
                <div className="p-2">
                  <button
                    className="ml-2 bg-transparent border border-orange-incubate hover:bg-orange-incubate   py-2 px-4  hover:border-transparent rounded"
                    onClick={this.afficherCom}>
                    Afficher les commentaires
                  </button>
                </div>
              ) : null}
              <div>
                {this.state.isAdmin ? (
                  this.state.synthese_boussole.synthese === "" ||
                  Object.keys(this.state.synthese_boussole).length === 0 ? (
                    <button
                      onClick={() => this.Synthese()}
                      className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                      Ajouter la Synthese
                    </button>
                  ) : null
                ) : null}
                {this.state.isAdmin ? (
                  <button
                    onClick={() => this.PlanAction()}
                    className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                    Ajouter le Plan Action
                  </button>
                ) : null}
                {this.state.isAdmin ? (
                  <button
                    onClick={() => this.Ambassadeur()}
                    className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                    Ajouter Ambassadeur
                  </button>
                ) : null}
              </div>
            </div>
            <div>
              {this.state.buttonCommentaire ? (
                <Commentaire dataCommentaire={this.state.dataCommentaire} />
              ) : null}
            </div>
          </div>

          {this.state.isAdmin ? (
            <div className="flex justify-center rounded-lg shadow-lg border mx-auto mt-3 mb-3 text-orange-incubate border-orange-incubate hover:bg-orange-incubate hover:text-white w-32 p-2">
              <ReactToPrint
                trigger={() => <button>Exporter</button>}
                content={() => this.componentRef.current}
              />
            </div>
          ) : null}
        </>
      );
    } else if (this.state.resultatN1.length > 0) {
      return (
        <>
          <div ref={this.componentRef}>
            <div className="flex justify-center">
              <img src={AdeoLogo} alt="" className="h-24 " />
            </div>
            <div className="flex flex-col items-center justify-center h-full ">
              <h1 className="text-orange-incubate text-3xl">
                Resultat Boussole {this.state.date}
              </h1>
            </div>
            <div className="flex justify-center text-center   text-md  mt-1 p-2 mb-2 items-center space-x-5   ">
              <p className="mr-2">
                Taux de participation :{" "}
                {this.state.division_tauxParticipation >= 1 ? (
                  <span className="bg-green-400 rounded-sm p-1 ml-1">
                    {this.state.taux_de_participation}
                  </span>
                ) : this.state.division_tauxParticipation < 1 &&
                  this.state.division_tauxParticipation >= 0.5 ? (
                  <span className="bg-orange-400 rounded-sm p-1 ml-1 ">
                    {this.state.taux_de_participation}
                  </span>
                ) : (
                  <span className="bg-red-400 rounded-sm p-1 ml-1 ">
                    {this.state.taux_de_participation}
                  </span>
                )}
              </p>
              {this.state.isAdmin ? (
                <p>
                  ROTI :
                  {this.state.moyenneRoti >= 3 ? (
                    <span className="bg-green-400 rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  ) : this.state.moyenneRoti < 3 &&
                    this.state.moyenneRoti > 2 ? (
                    <span className="bg-orange-400  rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  ) : (
                    <span className="bg-red-400  rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  )}
                </p>
              ) : null}
              <p className=" p-1">Ambassadeur :</p>
              {this.state.ambassadeurBoussole.length > 0 ? (
                this.state.ambassadeurBoussole.map((e) =>
                  e.nom === null ? (
                    <>
                      <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                        Pas d'ambassadeur, veuillez les définir svp
                      </p>
                    </>
                  ) : (
                    <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                      {e.nom}
                    </p>
                  )
                )
              ) : (
                <>
                  <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                    Pas d'ambassadeur, veuillez les définir svp
                  </p>
                </>
              )}
            </div>

            <div className="">
              <h1 className="text-white text-xl text-center mt-1 p-2 mb-2 bg-bleu-incubate w-96 mx-auto">
                {this.state.nomEquipe}
              </h1>
              <div className=" flex space-x-20 p-1  justify-center  ">
                <Table
                  criteres={this.state.criteres}
                  moyenne={this.state.moyenne}
                  moyenne_des_moyenne_1={this.state.moyenne_des_moyenne_Q1.toFixed(
                    2
                  )}
                  moyenne_des_moyenne_2={this.state.moyenne_des_moyenne_Q2.toFixed(
                    2
                  )}
                />

                <div className="w-1/3">
                  <BublleChart data={this.state.moyenne} />
                </div>
              </div>
              <div className="flex justify-center space-x-20 p-1 items-start ">
                {this.state.synthese_boussole.synthese === "" ||
                Object.keys(this.state.synthese_boussole).length === 0 ? (
                  <p className="text-center text-xl mt-2">
                    Merci de rédiger la synthèse
                  </p>
                ) : (
                  <div className="border border-gray-900 bg-slate-50 rounded w-1/3  p-3 mt-2">
                    <Synthese data={this.state.synthese_boussole} isAdmin={this.state.isAdmin}/>
                  </div>
                )}
                <div className="w-1/3">
                  <PlanActionEquipe idBoussole={this.state.idBoussole} isAdmin={this.state.isAdmin}/>
                </div>
              </div>
            </div>
            <div className="mt-5 flex justify-center  items-center ">
              {this.state.isAdmin ? (
                <div className="p-2">
                  <button
                    className="ml-2 bg-transparent border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded"
                    onClick={this.afficherCom}>
                    Afficher les commentaires
                  </button>
                </div>
              ) : null}
              <div>
                {this.state.isAdmin ? (
                  this.state.synthese_boussole.synthese === "" ||
                  Object.keys(this.state.synthese_boussole).length === 0 ? (
                    <button
                      onClick={() => this.Synthese()}
                      className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                      Ajouter la Synthese
                    </button>
                  ) : null
                ) : null}

                {this.state.isAdmin ? (
                  <button
                    onClick={() => this.PlanAction()}
                    className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                    Ajouter le Plan Action
                  </button>
                ) : null}
                {this.state.isAdmin ? (
                  <button
                    onClick={() => this.Ambassadeur()}
                    className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                    Ajouter Ambassadeur
                  </button>
                ) : null}
              </div>
            </div>
            <div>
              {this.state.buttonCommentaire ? (
                <Commentaire dataCommentaire={this.state.dataCommentaire} />
              ) : null}
            </div>
          </div>

          {this.state.isAdmin ? (
            <div className="flex justify-center rounded-lg shadow-lg border mx-auto mt-3 mb-3 text-orange-incubate border-orange-incubate hover:bg-orange-incubate hover:text-white w-32 p-2">
              <ReactToPrint
                trigger={() => <button>Exporter</button>}
                content={() => this.componentRef.current}
              />
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <div ref={this.componentRef}>
            <div className="flex justify-center">
              <img src={AdeoLogo} alt="" className="h-24 mb-3" />
            </div>
            <div className="flex flex-col items-center justify-center h-full ">
              <h1 className="text-orange-incubate text-3xl">
                Resultat Boussole {this.state.date}
              </h1>
            </div>
            <div></div>
            <h1 className="text-white text-xl text-center mt-1 p-2 mb-2 bg-bleu-incubate w-96 mx-auto">
              {this.state.nomEquipe}
            </h1>
            <div className="flex justify-center text-center mx-auto  text-md  mt-1 p-2 mb-2 items-center space-x-5   ">
              <p className="mr-2">
                Taux de participation :{" "}
                {this.state.division_tauxParticipation >= 1 ? (
                  <span className="bg-green-400 rounded-sm p-1 ml-1">
                    {this.state.taux_de_participation}
                  </span>
                ) : this.state.division_tauxParticipation < 1 &&
                  this.state.division_tauxParticipation >= 0.5 ? (
                  <span className="bg-orange-400 rounded-sm p-1 ml-1 ">
                    {this.state.taux_de_participation}
                  </span>
                ) : (
                  <span className="bg-red-400 rounded-sm p-1 ml-1 ">
                    {this.state.taux_de_participation}
                  </span>
                )}
              </p>
              {this.state.isAdmin ? (
                <p>
                  ROTI :
                  {this.state.moyenneRoti >= 3 ? (
                    <span className="bg-green-400 rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  ) : this.state.moyenneRoti < 3 &&
                    this.state.moyenneRoti > 2 ? (
                    <span className="bg-orange-400  rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  ) : (
                    <span className="bg-red-400  rounded-sm p-1 ml-1">
                      {this.state.moyenneRoti}
                    </span>
                  )}
                </p>
              ) : null}
              <p className=" p-1">Ambassadeur :</p>
              {this.state.ambassadeurBoussole.length > 0 ? (
                this.state.ambassadeurBoussole.map((e) =>
                  e.nom === null ? (
                    <>
                      <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                        Pas d'ambassadeur, veuillez les définir svp
                      </p>
                    </>
                  ) : (
                    <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                      {e.nom}
                    </p>
                  )
                )
              ) : (
                <>
                  <p className="text-bleu-incubate border border-bleu-incubate mr-2 rounded-md p-1">
                    Pas d'ambassadeur, veuillez les définir svp
                  </p>
                </>
              )}
            </div>

            <div className=" flex space-x-20 p-1  justify-center">
              <Table
                criteres={this.state.criteres}
                moyenne={this.state.moyenne}
                moyenne_des_moyenne_1={this.state.moyenne_des_moyenne_Q1.toFixed(
                  2
                )}
                moyenne_des_moyenne_2={this.state.moyenne_des_moyenne_Q2.toFixed(
                  2
                )}
              />
              <div className="w-1/3">
                <BublleChart data={this.state.moyenne} />
              </div>
            </div>

            <div className="flex justify-center space-x-20 p-1 items-start ">
              {this.state.synthese_boussole.synthese === "" ||
              Object.keys(this.state.synthese_boussole).length === 0 ? (
                <p className="text-center text-xl mt-2">
                  Merci de rédiger la synthèse
                </p>
              ) : (
                <div className="border border-gray-900 bg-slate-50 rounded w-1/3  p-3 mt-2">
                  <Synthese data={this.state.synthese_boussole} isAdmin={this.state.isAdmin} />
                </div>
              )}
              <div className="w-1/3">
                <PlanActionEquipe idBoussole={this.state.idBoussole} isAdmin={this.state.isAdmin} />
              </div>
            </div>

            <div className="mt-5 flex justify-center  items-center">
              {this.state.isAdmin ? (
                <div className="p-2">
                  <button
                    className="ml-2 bg-transparent border border-orange-incubate hover:bg-orange-incubate   py-2 px-4  hover:border-transparent rounded"
                    onClick={this.afficherCom}>
                    Afficher les commentaires
                  </button>
                </div>
              ) : null}
              <div>
                {this.state.isAdmin ? (
                  this.state.synthese_boussole.synthese === "" ||
                  Object.keys(this.state.synthese_boussole).length === 0 ? (
                    <button
                      onClick={() => this.Synthese()}
                      className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                      Ajouter la Synthese
                    </button>
                  ) : null
                ) : null}
                {this.state.isAdmin ? (
                  <button
                    onClick={() => this.PlanAction()}
                    className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                    Ajouter le Plan Action
                  </button>
                ) : null}
                {this.state.isAdmin ? (
                  <button
                    onClick={() => this.Ambassadeur()}
                    className=" ml-2  border border-orange-incubate hover:bg-orange-incubate  py-2 px-4  hover:border-transparent rounded">
                    Ajouter Ambassadeur
                  </button>
                ) : null}
              </div>
            </div>

            <div>
              {this.state.buttonCommentaire ? (
                <Commentaire dataCommentaire={this.state.dataCommentaire} />
              ) : null}
            </div>
          </div>

          {this.state.isAdmin ? (
            <div className="flex justify-center rounded-lg shadow-lg border mx-auto mt-3 mb-3 text-orange-incubate border-orange-incubate hover:bg-orange-incubate hover:text-white w-32 p-2">
              <ReactToPrint
                trigger={() => <button>Exporter</button>}
                content={() => this.componentRef.current}
              />
            </div>
          ) : null}
        </>
      );
    }
  }
}

//Export
export default withRouter(ResultatBoussole);
