import React, { memo } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Synthese = memo((props) => {
  const navigate = useNavigate();
  const synthese_boussole = props.data;
  const isAdmin = props.isAdmin;



  return (
    <>
      <h2 className="text-2xl text-orange-incubate text-center mt-5">
        Synthese
      </h2>

      <div className="relative mt-5 p-4">
        <p className="whitespace-pre-line mt-1 text-md break-all">
          {synthese_boussole.synthese}
        </p>
        {isAdmin ? (
          <button
            className="absolute bottom-0 right-0 mb-2 mr-2"
            onClick={() => {
              navigate(`/modifierSynthese/${synthese_boussole.id_boussole}`);
            }}>
            <AiOutlineEdit className="text-orange-incubate" />
          </button>
        ) : null}
      </div>
    </>
  );
});

export default Synthese;
