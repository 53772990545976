import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { BsCircleFill } from "react-icons/bs"
import Select from 'react-select';

class EditCriteres extends Component {
    constructor(props){
        super(props);
        //Variables utilisées dans la page
        this.state = {
            listeStatut : [{value : 0, label : 'Actif'}, {value : 1, label:'Inactif'}],

            //Fonctionnement de la page
            showModal : false,
            showModalAnnuler : false,
            modif : false,
            loadingCritere: true,

            //Champs du formulaire
            idSociete : '',
            libelle : '',
            description : '',
            vert : '',
            rouge : '',
            image : '',
            statut : '',
            modifiable : '',
            logo : null,
            imagePreVisu : '',

            //Erreur de champs
            libelleError : '',
            descriptionError : '',
            vertError : '',
            rougeError : '',
            imageError : ''
        }
    }

    //Fonction détectant un changement dans un champs
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
        this.setState({
            modif: true
          });
    }

    //fonction detectant un changement dans le champs visu
    handleChangeStatut = (event) => {
        this.setState({
          statut : event.value,
        });
        this.setState({
            modif: true
          });
    }

    //Fonction pour récupérer l'image uploadé
    fileSelectedHandler = event => {
        this.setState({logo : event.target.files[0], imagePreVisu : URL.createObjectURL(event.target.files[0])})
        this.setState({
            modif: true
          });
    }   

    //Fonctiond e validation des champs
    validate = () => {
        let libelleError = '';
        let descriptionError = '';

        //Validation libelle
        if(!this.state.libelle){
          libelleError = "Le libelle du critère doit être renseigné"
        }
        //Validation decription
        if(!this.state.description){
          descriptionError = "La description du critère doit être renseignée"
        }
        //Retour d'erreur
        if(libelleError || descriptionError  ){
            this.setState({libelleError, descriptionError});
            return false;   
        }else{
            this.setState({libelleError, descriptionError});
            return true; 
        }
    }

    //Fonction appellant l'api pour l'enregistrement des modifications
    Enregistrer = (event) => {
        event.preventDefault();

        if(this.validate()){
            const body = {
                idCritere : this.props.params.id,
                libelle : this.state.libelle,
                description : this.state.description,
                statut : this.state.statut === 0 ? "Actif" : "Inactif"
            };
    
            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };
    
            fetch('/api/criteresBoussole/modify', requestOptions)
                .then( res => {
                    if(res.status === 201){
                        //Appel api pour changer l'image si cette dernière à été modifié
                        if(this.state.logo){
                            const formData = new FormData();
                            formData.append('image', this.state.logo);
                            formData.append('idCritere', this.props.params.id);
                            formData.append('idSociete', this.state.idSociete);
                           

                            const requestOptionsImage = {
                                method : 'POST', 
                                body : formData,
                                headers:{          
                                    'Accept': 'multipart/form-data',
                                },
                                credentials : 'include'
                            };

                            fetch('/api/criteresBoussole/upload', requestOptionsImage)
                                .then(reponse => {
                                   
                                    if(reponse.status === 201){
                                        alert('Critère modifié avec succès !')
                                        this.props.navigate(`/criteres/${this.state.idSociete}`);
                                    }else if(reponse.status === 449){
                                        alert("Le critère à été modifié, mais l'image ne l'as pas été car le format n'est pas le bon ...");
                                    }else{
                                        alert("Erreur lors de la modification de l'image du critère ...");
                                        throw new Error(reponse.error);
                                    }
                                }).catch(err => {
                                    console.error(err);
                                })
                        }else{
                            alert('Critère modifié avec succès !')
                            this.props.navigate(`/criteres/${this.state.idSociete}`);
                        }
                    }else if(res.status === 449){
                        alert(`Le critère ${this.state.libelle} est déjà existant pour cette société ...`);
                    }else {
                        alert("Erreur lors de la modification du critère ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                  console.error(err);
                })
        }
    }

//Fonction de supression appellant l'api
    supprimer = (event) => {
        event.preventDefault();

        this.setState({showModal:false});
        
        const body = {
            idCritere : this.props.params.id,
            idSociete : this.state.idSociete
        };

        const requestOptions = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
          };

          fetch('/api/criteresBoussole/deleteCritere', requestOptions)
          .then( res => {
              if(res.status === 200){
                  alert('Critère supprimé !')
                  this.props.navigate(`/criteres/${this.state.idSociete}`);
              }else {
                  alert("Erreur lors de la suppression du critère ...");
                  throw new Error(res.error);
              }
          }).catch(err => {
            console.error(err);
          })
        
        
    }

    //Fonction de récupération des données au chargement de la page
    componentDidMount(){
        
        //Récupération de l'individu à modifier
        const body = {
            idCritere : this.props.params.id,
        };

        const getByIdOptions = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        fetch('/api/criteresBoussole/getCritereById', getByIdOptions)
            .then(res => {
                return res.json();  
            }).then(data => {
                
                this.setState ({
                    idSociete : data.id_societe,
                    libelle : data.libelle,
                    description : data.description,
                
                    image : data.Logo,
                    statut : data.statut === "Actif" ? 0 : 1,
                    modifiable : true
                    
                });
                this.setState({loadingCritere : false})
            }).catch(error => {
                console.log(error);
            });
    }

    //Fonction de rendu du composant
    render(){
        if (this.state.loadingCritere){
            return <h1> </h1>
        }else{
            return (
                <div className='flex flex-col items-center justify-center h-full pt-32'>
                    <h1 className="text-orange-incubate text-3xl">Modification d'un critère</h1>
                    <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
                        <div className="md:mb-4">
                            <label htmlFor='libelle' className="block font-semibold">Libelle du critère</label>
                            <input disabled={!this.state.modifiable} type='text' name='libelle' placeholder="Libelle du critère" value={this.state.libelle} onChange={(event)=>this.handleInputChange(event)} className={!this.state.modifiable ? "bg-gray-300 shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2" : "shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"}/>
                            {this.state.libelleError ? <div className="text-red-600">{this.state.libelleError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='description' className="block font-semibold">Description</label>
                            <textarea disabled={!this.state.modifiable} name='description' placeholder="Description" value={this.state.description} onChange={(event)=>this.handleInputChange(event)} className={!this.state.modifiable ? "bg-gray-300 shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2" : "shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"} rows={8}/>
                            {this.state.descriptionError ? <div className="text-red-600">{this.state.descriptionError}</div> : null }
                        </div>
                       
                        <div className="md:mb-4">
                            <label htmlFor='image' className="block font-semibold pb-1">Image</label>
                            <input type="file" name='image' accept="image/*" onChange={this.fileSelectedHandler}/>
                            {!this.state.logo ? <img src={this.state.image} alt="Image du critère" className="w-96 h-auto"/> : <img src={this.state.imagePreVisu} alt="Image du critère" className="w-96 h-auto"/>}
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='statut' className="block font-semibold">Statut</label>
                                {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (<blockquote>"{this.state.ariaFocusMessage}"</blockquote>)}
                                <Select
                                    aria-labelledby="Statut-label"
                                    inputId="Statut-input"
                                    name="statut"
                                    onMenuOpen={this.onMenuOpen}
                                    onMenuClose={this.onMenuClose}
                                    options={this.state.listeStatut}
                                    onChange={this.handleChangeStatut}
                                    defaultValue={this.state.listeStatut[this.state.statut]}
                                />                      
                        </div>
                        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={this.Enregistrer}>Enregistrer les modifications</button>
                            <button type='button' className={!this.state.modifiable?"transition ease-in-out delay-150 bg-gray-500 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2":"transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2"} onClick={() => {this.setState({showModal:this.state.modifiable})}} >Supprimer</button>
                            {this.state.modif ? ( <>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={() => {this.setState({showModalAnnuler:true})}}>Annuler</button>
                            </>)
                        : <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={()=>{this.props.navigate(`/criteres/${this.state.idSociete}`)}}>Annuler</button> }
                        </div>
                        
                    </form>

                    {/*Modal Suppr*/}

            {this.state.showModal ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">ATTENTION</h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Vous êtes sur le point de supprimer le critère "{this.state.libelle}" !
                            <br />Etes vous sûr de vouloir continuer ?
                        </p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModal:false})}>Annuler</button>
                        <button
                            className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={this.supprimer} >Supprimer</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {/*Modal annuler*/}

            {this.state.showModalAnnuler ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">ATTENTION</h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Vos modifications ne seront pas sauvegardées !
                            <br />Etes vous sûr de vouloir continuer ?
                        </p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalAnnuler:false})}>Non</button>
                        <button type='button' className="bg-orange-incubate text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={()=>{this.props.navigate(`/criteres/${this.state.idSociete}`)}}>Oui</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
                </div>
            );
        }
    }
}

//Export
export default withRouter(EditCriteres);