import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import Select from 'react-select';

class Register extends Component {
    constructor(props){
        super(props);
        //Variables utilisé pour la page
        this.state = {
            //Fonctionnement de la page
            loadingProfil : true,
            listeProfils : [],
            listeEquipes : [],
            listeSocietes : [],

            //Champs du formulaire
            prenom : '',
            nom : '',
            mail : '',
            profil : '',
            equipe : '',
            societe : '',

            //Erreur de champs
            prenomError : '',
            nomError : '',
            mailError : '',
            profilError : '',
            societeError : '',
            equipeError : '',

            //select
            isMenuOpen : false,
            ariaFocusMessage : "",
        }

        
    }


    //handleChange pour les profil
    handleChangeProfil = (event) => {
        this.setState({
          profil : event.value,
        });
    }

    //handleChange pour les societe
    handleChangeSociete = (event) => {
        
        this.setState({
          societe : event.value,
        });
        this.getEquipe(event.value);
    }

     //handleChange pour les équipe
    handleChangeEquipe = (event) => {
        this.setState({
          equipe : event,
        });
        
    }

    //handleChange pour les autres champs
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
    }

    //Fonction pour les menus
    onMenuOpen = () => this.setState({isMenuOpen : true});
    onMenuClose = () => this.setState({isMenuOpen : false});

    //Fonction de validation
    validate = () => {
        let prenomError = '';
        let nomError = '';
        let mailError = '';
        let profilError = '';


        //Validation prenom
        if(!this.state.prenom){
            prenomError = "Le prénom doit être renseigné"
        }
        if(this.state.prenom.length > 45){
            prenomError = "Le prenom est trop long"
        }

        //Validation nom
        if(!this.state.nom){
            nomError = "Le nom doit être renseigné"
        }
        if(this.state.nom.length > 45){
            nomError = "Le nom est trop long"
        }

        //Validation mail
        if(!this.state.mail.includes('@')){
            mailError = "L'adresse mail n'est pas valide"
        }
        if(this.state.mail.length > 255){
            mailError = "L'adresse mail est trop longue"
        }

        //validation profil
        if(!this.state.profil){
            profilError = "Le profil doit être renseigné"
        }

        //Retour d'erreur
        if(prenomError || nomError || mailError || profilError ){
            this.setState({prenomError, nomError, mailError,profilError});
            return false;   
        }else{
            this.setState({prenomError, nomError, mailError,profilError});
            return true; 
        }
    }   

//Fonction d'appel API pour l'enregistrement
    onClick = (event) => {
        event.preventDefault();

        if(this.validate()){
            const body = {
                pseudo: "",
                nom : this.state.nom,
                prenom : this.state.prenom,
                mail : this.state.mail,
                profil : this.state.profil,
                equipe : this.state.equipe,
                societe : this.state.societe,
            };
    
            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };
    
            fetch('/api/register', requestOptions)
                .then( res => {
                    if(res.status === 201){
                        alert('Utilisateur créé avec succès !')
                        this.props.navigate('/users');
                    }else if(res.status === 449){
                        alert(`L'adresse mail ${this.state.mail} est déjà utilisée ...`);
                    }else {
                        alert("Erreur lors de la création de l'utilisateur ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                  console.error(err);
                })
        }
    }

    //Appel de récupération des équipe
    getEquipe = (societe) => {
        const body = {
            idSociete : societe,
        };

        const requestOptions = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
          };

        //liste des categories
        fetch('/api/equipes/getAllEquipes', requestOptions)
            .then(res => {
                return res.json();  
            }).then(data => {
                let equipes = data.map(equipe => {
                    return {value : equipe.ID_EQUIPE, label : equipe.LIBELLE_EQUIPE}
                });
                this.setState ({
                listeEquipes: equipes,
            });

            }).catch(error => {
                console.log(error);
            });
    }

    //Fonction de chargement des information au chargement de la page
    componentDidMount(){
        //Options de requete
        const requestOptions = {
            method : 'GET',
            header : {
                'Content-Type' : 'application:json'
            }
        };

        fetch('/api/societes/getAllSocietes', requestOptions)
        .then(res => {
            return res.json();  
        }).then(data => {
            let societes = data.map(societe => {
                return {value : societe.ID_SOCIETE, label : societe.LIBELLE_SOCIETE}
            });
            this.setState ({
                listeSocietes: societes,
            });
            this.getEquipe(societes[0].value);
            this.setState({loadingSociete : false});
        }).catch(error => {
            console.log(error);
        });

        //liste des profils
        fetch('/api/register/getProfil', requestOptions)
            .then(res => {
                return res.json();  
            }).then(data => {
                let profils = data.map(profil => {
                    return {value : profil.ID_PROFIL, label : profil.LIBELLE_PROFIL}
                });

                this.setState ({
                    listeProfils: profils,
                });
                this.setState({loadingProfil : false});
            }).catch(error => {
                console.log(error);
            });

    }

    
    //Fonction de rendu
    render(){
        if (this.state.loadingProfil ){
            return <h1> </h1>
        }else{
            return (
                <div className='flex flex-col items-center justify-center h-full pt-32'>
                    <h1 className="text-orange-incubate text-3xl">Création d'un utilisateur</h1>
                    <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
                        <div className="md:mb-4">
                            <label htmlFor='nom' className="block font-semibold">Nom</label>
                            <input type='text' name='nom' placeholder='Nom' value={this.state.nom} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                            {this.state.nomError ? <div className="text-red-600">{this.state.nomError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='prenom' className="block text-l font-semibold mb-2">Prenom</label>
                            <input type='text' name='prenom' placeholder='Prenom' value={this.state.prenom} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                            {this.state.prenomError ? <div className="text-red-600">{this.state.prenomError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='mail' className="block text-l font-semibold mb-2">Adresse mail</label>
                            <input type='email' name='mail' placeholder='Adresse mail' value={this.state.mail} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                            {this.state.mailError ? <div className="text-red-600">{this.state.mailError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='profil' className="block text-l font-semibold mb-2">Profil</label>
                            {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (
                                <blockquote>"{this.state.ariaFocusMessage}"</blockquote>
                                )}
                                    <Select
                                aria-labelledby="Profil-label"
                                inputId="Profil-input"
                                name="Profil"
                                onMenuOpen={this.onMenuOpen}
                                onMenuClose={this.onMenuClose}
                                options={this.state.listeProfils}
                                onChange={this.handleChangeProfil}
                                />
                                {this.state.profilError ? <div className="text-red-600">{this.state.profilError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='societe' className="block text-l font-semibold mb-2">Societe</label>
                            {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (
                                <blockquote>"{this.state.ariaFocusMessage}"</blockquote>
                                )}
                                    <Select
                                aria-labelledby="Societe-label"
                                inputId="Societe-input"
                                name="Societe"
                                onMenuOpen={this.onMenuOpen}
                                onMenuClose={this.onMenuClose}
                                options={this.state.listeSocietes}
                                onChange={this.handleChangeSociete}
                                />
                                {this.state.societeError ? <div className="text-red-600">{this.state.societeError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='equipe' className="block text-l font-semibold mb-2">Equipe</label>
                            {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (
                                <blockquote>"{this.state.ariaFocusMessage}"</blockquote>
                                )}
                                    <Select
                                aria-labelledby="Equipe-label"
                                inputId="Equipe-input"
                                name="Equipe"
                                isMulti
                                onMenuOpen={this.onMenuOpen}
                                onMenuClose={this.onMenuClose}
                                options={this.state.listeEquipes}
                                onChange={this.handleChangeEquipe}
                                />
                                {this.state.equipeError ? <div className="text-red-600">{this.state.equipeError}</div> : null }
                        </div>
                        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={()=>{this.props.navigate("/users")}}>Retour</button>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={this.onClick}>Créer l'utilisateur</button>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

//exports
export default withRouter(Register);