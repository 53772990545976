import React, { memo, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Synthese from "./Synthese";

import PlanActionEquipe from "./../PlanActionEquipe/PlanActionEquipe";

const SyntheseBoussole = memo((props) => {
  const navigate = useNavigate();
  const id_boussole = useParams().id;
  const [id_individu, setId_individu] = useState();
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [synthese, setSynthese] = useState();
  const [synthese_boussole, setSynthese_boussole] = useState();
  const [action, setAction] = useState();
  const [libelleError, setLibelleError] = useState();
  const [loadingSynthese, setLoadingSynthese] = useState(true);
  const [limited, setLimited] = useState(true);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "resultat-boussole",
    onAfterPrint: () => alert("le fichier PDF est créé."),
  });

  const handleInputChangeSynthese = (event) => {
    setSynthese(event.target.value);
  };

  const validate = () => {
    let Error = "";

    //Validation libelle
    if (!synthese) {
      Error = "Selectionner une reponse";
      alert("veuillez entrer la synthese svp");
    }

    //Retour d'erreur

    if (Error) {
      setLibelleError(Error);
      return false;
    } else {
      setLibelleError(Error);
      return true;
    }
  };
  const RegisterSynthese = () => {
    if (validate()) {
      const body = {
        synthese: synthese,
        id_individu: id_individu,
        idBoussole: id_boussole,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/syntheseBoussole", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            alert("la synthese a été rajouté avec succès !");
            navigate(`/resultatBoussole/${id_boussole}`);
          } else {
            alert("la synthèse existe déjà en bdd");
            navigate(`/resultatBoussole/${id_boussole}`);

            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (limited) {
      const requestOptions = {
        method: "GET",
        header: {
          "Content-Type": "application:json",
        },
      };

      fetch("/api/register/me", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setId_individu(data.ID_INDIVIDU);
          setNom(data.NOM);
          setPrenom(data.PRENOM);
        })
        .catch((error) => {
          console.log(error);
        });

      const bodyIdBousolle = {
        idBoussole: id_boussole,
      };

      const get = {
        method: "POST",
        body: JSON.stringify(bodyIdBousolle),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/syntheseBoussole/getSyntheseByBoussole", get)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setSynthese_boussole(data);
          setLoadingSynthese(false);
        })
        .catch((error) => {
          console.log(error);
        });
      setLimited(false);
    }
  });
  return (
    <>
      <div>
        <div className="flex justify-center ">
            <p className="">
              {nom} {prenom}
            </p>
          </div>

        <div className=" flex-col justify-center">
          <h2 className="flex justify-center mb-3 text-xl text-orange-incubate">
            Synthese Boussole
          </h2>
          <div className="flex justify-center">
            <div className="w-1/3 mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="  bg-white rounded-t-lg dark:bg-gray-800 ">
                <textarea
                  onChange={handleInputChangeSynthese}
                  rows="30
                    "
                  className="w-full px-4 py-2  text-sm text-gray-900 bg-white  dark:bg-gray-800  dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer la synthese ...."
                  required></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            onClick={RegisterSynthese}
            className="border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg  text-white mt-4">
            Valider
          </button>
        </div>
      </div>
    </>
  );
});

export default SyntheseBoussole;
