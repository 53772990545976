import React, { memo } from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const CommentaireBoussole = memo(() => {
  const [commentaire, setCommentaire] = useState("");
  const [id_boussole, setId_boussole] = useState("");
  const [id_individu, setId_individu] = useState("");
  const id_boussole_individu = useParams().id;
  const [libelleError, setLibelleError] = useState("");
  const [limited, setLimited] = useState(true);
  const [roti, setRoti] = useState("");

  const navigate = useNavigate();

  const handleInputChangeCommentaire = (event) => {
    setCommentaire(event.target.value);
    
  };
  const handleInputChangeRoti = (event) => {
    setRoti(event.target.value);
   
  };
  const validate = () => {
    let Error = "";

    //Validation libelle
    if (!commentaire) {
      Error = "Selectionner une reponse";
      alert("Veuillez saisir un commentaire s'il vous plaît");
    }
    if (!roti) {
      Error = "Selectionner une reponse";
      alert("Veuillez saisir une note s'il vous plaît");
    }

    //Retour d'erreur

    if (Error) {
      setLibelleError(Error);
      return false;
    } else {
      setLibelleError(Error);
      return true;
    }
  };

  useEffect(() => {
    if (limited) {
      const body = {
        id_boussole_individu: id_boussole_individu,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/boussole/getBoussoleIndividu", requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setId_individu(data[0].id_individu);
          setId_boussole(data[0].id_boussole);
        })
        .catch((error) => {
          console.log(error);
        });
      setLimited(false);
    }
  });

  const RegisterCommentaire = () => {
    if (validate()) {
      const body = {
        roti:roti,
        commentaire: commentaire,
        id_individu: id_individu,
        idBoussole: id_boussole,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/commentaireBoussole/", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            alert("le commentaire a été rajouté avec succès !");
            navigate(`/resultatBoussole/${id_boussole}`);
          } else {
            alert("Vous avez déjà laissé un commentaire pour cette boussole ");
            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <h2 className="flex justify-center mb-3 text-xl text-orange-incubate">
        Merci de nous laisser un commentaire
      </h2>
      <div className="mx-auto w-1/4">
        <div className=" mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
          <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
            <textarea
              onChange={handleInputChangeCommentaire}
              rows="7"
              className="w-full  px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
              placeholder="Merci d'entrer un commentaire ...."
              required
            ></textarea>
          </div>
        </div>
      </div>

      <div className="flex-col space-y-6 justify-center mt-2 mb-5   ">
        <h4 className=" mt-5 flex justify-center  text-orange-incubate text-xl ">
          ROTI (Comment évaluez-vous notre service dans son ensemble ?)
        </h4>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className=" flex  justify-center space-x-4 "
        >
          

          <div className="flex flex-col">
            <label>1</label>
            <input
              type="radio"
              name="reponse_1"
              chechek={roti === 1}
              onChange={handleInputChangeRoti}
              value={1}
              className="accent-red-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
            />
          </div>

          <div className="flex flex-col">
            <label className="">2</label>
            <input
              type="radio"
              name="reponse_1"
              chechek={roti === 2}
              onChange={handleInputChangeRoti}
              value={2}
              className="accent-orange-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
            />
          </div>

          <div className="flex flex-col">
            <label className="">3</label>
            <input
              type="radio"
              name="reponse_1"
              chechek={roti === 3}
              onChange={handleInputChangeRoti}
              value={3}
              className="accent-orange-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600  "
            />
          </div>

          <div className="flex flex-col">
            <label className="">4</label>
            <input
              type="radio"
              name="reponse_1"
              chechek={roti === 4}
              onChange={handleInputChangeRoti}
              value={4}
              className="accent-green-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
            />
          </div>

          <div className="flex flex-col">
            <label className="ml-1">5</label>
            <input
              type="radio"
              name="reponse_1"
              chechek={roti === 5}
              onChange={handleInputChangeRoti}
              value={5}
              className=" accent-green-500 focus:accent-green-500 mb-1  w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={RegisterCommentaire}
          className="border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg  text-white mt-4"
        >
          Valider
        </button>
      </div>
    </>
  );
});

export default CommentaireBoussole;
