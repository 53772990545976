import React, { memo, useState } from "react";

const Table = memo((props) => {
  
 
  const moyenne = props.moyenne;
 
  
  const moyenne_des_moyenne_1 = props.moyenne_des_moyenne_1;
 
  const moyenne_des_moyenne_2 = props.moyenne_des_moyenne_2;
  

  return (
    <>
      <table className="items-center bg-transparent w-1/3 border-collapse">
        <thead>
          <tr>
            <td className="bg-orange-100 text-bleu-incubate align-middle border border-solid border-bleu-incubate p-3 text-xs uppercase border-r-0 whitespace-nowrap text-left">
              Criteres
            </td>
            <td className="bg-orange-100 text-bleu-incubate align-middle border border-solid border-bleu-incubate p-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap text-center">
              FF
            </td>
            <td className="bg-orange-100 text-bleu-incubate align-middle border border-solid border-bleu-incubate p-3 text-xs uppercase border-l-0 whitespace-nowrap text-center">
              OR
            </td>
          </tr>
        </thead>

        <tbody>
          {moyenne.map((moyenne, index) => (
            <tr className="border border-bleu-incubate" key={index}>
              <td className="text-left border border-bleu-incubate">{moyenne.libelle}</td>
              <td className={`text-center ${moyenne.moyenneQ1 >= 3 ? 'bg-green-300' : moyenne.moyenneQ1 >= -2.99 && moyenne.moyenneQ1 <= 2.99 ? 'bg-orange-200' : 'bg-red-300'}`}>
                {moyenne.moyenneQ1.toFixed(2)}
              </td>
              <td className={`text-center ${moyenne.moyenneQ2 >= 3 ? 'bg-green-300' : moyenne.moyenneQ2 >= -2.99 && moyenne.moyenneQ2 <= 2.99 ? 'bg-orange-200' : 'bg-red-300'}`}>
                {moyenne.moyenneQ2.toFixed(2)}
              </td>
            </tr>
          ))}

          <tr className="border border-bleu-incubate">
            <td className="text-center font-bold border bg-yellow-100 border-bleu-incubate">Note</td>
            <td className={`text-center ${moyenne_des_moyenne_1 >= 3 ? 'bg-emerald-400 font-bold' : moyenne_des_moyenne_1 >= -2.99 && moyenne_des_moyenne_1 <= 2.99 ? 'bg-orange-400 font-bold border border-bleu-incubate' : 'bg-red-400 font-bold border border-bleu-incubate'}`}>
              {moyenne_des_moyenne_1}
            </td>
            <td className={`text-center ${moyenne_des_moyenne_2 >= 3 ? 'bg-emerald-400 font-bold border border-bleu-incubate' : moyenne_des_moyenne_2 >= -2.99 && moyenne_des_moyenne_2 <= 2.99 ? 'bg-orange-400 font-bold border border-bleu-incubate' : 'bg-red-400 font-bold border border-bleu-incubate'}`}>
              {moyenne_des_moyenne_2}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
});

export default Table;
