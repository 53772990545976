import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AdeoLogo from "../../assets/images/adeo.png";


class MesBoussoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boussoles: [],
      statut: "",

      loading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    //Options de requete

    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
    };

    //liste des boussoles

    fetch("/api/boussole/getBoussoleByIndividu", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          boussoles: data,
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.boussoles));
  }

  render() {
    if (this.state.boussoles.length > 0) {
      return (
        <div className="flex flex-col items-center pt-32">
           <div className="flex justify-center ">
            <img src={AdeoLogo} alt="" className="h-24 " />
          </div>
          <h1 className="text-orange-incubate text-3xl">Mes Boussoles</h1>
          <div
            onSubmit={this.handleSubmit}
            className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-3/4 "
          >
            {this.state.boussoles.map((element, index) => (
              <div
                className="flex flex-col md:flex-row items-center justify-center m-5 p-5  border-t-2 "
                key={index}
              >
                <h1 className="basis-1/3 text-lg h-full ">
                  {element.date_creation}
                </h1>
                <h1 className="basis-1/3 text-lg h-full">
                  {element.LIBELLE_EQUIPE}
                </h1>
                <div className="basis-1/3 flex justify-end">
                  <Button element={element} />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        
        <div className="flex flex-col items-center justify-center h-full pt-32">
           <div className="flex justify-center ">
            <img src={AdeoLogo} alt="" className="h-24 " />
          </div>
          <h1 className="text-orange-incubate text-3xl">Mes Boussoles</h1>
          <h2 className="text-2xl pt-40 text-gray-300">Aucune boussole</h2>
        </div>
      );
    }
  }
}

function Button(element) {
  const navigate = useNavigate();
  if (element.element.statut === "Non commencé") {
    return (
      <button
        onClick={() => {
          navigate(`/reponseBoussole/${element.element.id_boussole}`);
        }}
        className="justify-center md:text-xl  py-2 px-5 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate"
      >
        <div className="flex flex-row items-center">
          <p className="px-2 ">Commencer</p>
          <BiRightArrow className="pt-1 " />
        </div>
      </button>
    );
  } else if (element.element.statut === "En cours") {
    return (
      <button
        onClick={() => {
          navigate(`/reponseBoussole/${element.element.id_boussole}`);
        }}
        className="justify-center md:text-xl py-2 px-8 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-yellow-400"
      >
        <div className="flex flex-row items-center">
          <p className="px-2">Continuer</p>
          <BiRightArrow className="pt-1" />
        </div>
      </button>
    );
  } else if (element.element.statut === "Terminé") {
    return (
      <div className="flex md:flex-row flex-col ">
        <button
          onClick={() => {
            navigate(`/resultatBoussole/${element.element.id_boussole}`);
          }}
          className="justify-center my-2 mx-2 md:text-l  py-2 px-5 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-green-400"
        >
          <div className="flex flex-row items-center">
            <p className="px-2 ">Voir mes resultats</p>
            <BiRightArrow className="pt-1" />
          </div>
        </button>
      </div>
    );
  } else {
    return <div> </div>;
  }
}

export default withRouter(MesBoussoles);
