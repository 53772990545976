import {Routes, Route} from "react-router-dom";

import Menu from "./components/Outils/Menu.jsx";
import MenuNonConnecté from "./components/Outils/MenuNonConnecté.jsx";
import Home from './components/Home/Home.jsx';
import Login from "./components/Login/Login.jsx";
import ProtectedRoute from './components/Outils/ProtectedRoute';
import AdminRoute from "./components/Outils/AdminRoute.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import CreationSocietes from "./components/CreationSocietes/CreationSocietes.jsx";
import Societes from "./components/Societes/Societes.jsx";
import EditSocietes from "./components/EditSocietes/EditSocietes.jsx";
import Register from "./components/Register/Register.jsx";
import CreationEquipes from "./components/CreationEquipes/CreationEquipes.jsx";
import Criteres from "./components/Criteres/Criteres.jsx";
import CreationCriteres from "./components/CreationCriteres/CreationCriteres.jsx";
import EditCriteres from "./components/EditCriteres/EditCriteres.jsx";
import Users from "./components/Users/Users.jsx";
import EditUser from "./components/EditUser/EditUser.jsx";

import MesBoussoles from "./components/MesBoussoles/MesBoussoles.jsx";

import EditMe from "./components/EditUser/EditMe";
import EquipeBoussole from "./components/EquipeBoussole/EquipeBoussole"
import Test from "./components/test"
import CreationBoussole from "./components/CreationBoussole/CreationBoussole"
import ReponseBoussole from "./components/ReponseBoussole/ReponseBoussole.jsx";
import ResultatBoussole from "./components/ResultatBoussole/ResultatBoussole.jsx";
import BubbleChart from './components/Graphique/BubbleChart';
import SyntheseBoussole from "./components/SyntheseBoussole/SyntheseBoussole.jsx";
import Table from "./components/Table/Table.jsx";
import CommentaireBoussole from "./components/CommentaireBoussole/CommentaireBoussole.jsx";
import Domaine from "./components/ResultatDomaine/Domaine.jsx";
import ResultatDomaine from "./components/ResultatDomaine/ResultatDomaine.jsx";
import PlanAction from './components/ResultatDomaine/PlanAction';
import TestAction from "./components/ResultatDomaine/testAction";
import FormPlanAction from "./components/ResultatDomaine/FormPlanAction.jsx";
import AmbassadeurBoussole from "./components/AmbassadeurBoussole/AmbassadeurBoussole.jsx";
import UserLogin from "./components/UserLogin/UserLogin.jsx";
import RegisterUser from "./components/RegisterUser/RegisterUser.jsx";
import EditSynthese from "./components/EditSynthese/EditSynthese.jsx";
import EditPlanAction from "./components/EditPlanAction/EditPlanAction.jsx";


export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<><MenuNonConnecté/><Home /></>} />
        <Route path='/test' element={<Table/>} />
        <Route path="/login" element={<> <Login/></>} />
        <Route path="/userLogin" element={<UserLogin/>} />
        <Route path="/RegisterUser" element={<RegisterUser/>} />
       
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<><Menu /> <Dashboard /></>}/>
          <Route path="/editUser" element={<> <EditMe /></>}/>
          <Route path="/reponseBoussole/:id" element={<><Menu /> <ReponseBoussole/></>}/>
          <Route path="/boussoleCommentaire/:id" element={<><Menu /> <CommentaireBoussole/></>}/>
          <Route path="/resultatBoussole/:id" element={<><Menu /> <ResultatBoussole/></>}/>
          <Route path="/mesBoussoles" element={<><Menu /> <MesBoussoles/></>}/>
          <Route path="/" element={<AdminRoute />}>
            <Route path="/domaines/:id" element={<><Menu /> <Domaine/></>}/>
            <Route path="/resultatDomaine/:id/:idSociete" element={<><Menu /> <ResultatDomaine/></>}/>
            <Route path="/syntheseBoussole/:id" element={<><Menu /> <SyntheseBoussole/></>}/>
            <Route path="/ambassadeurBoussole/:id" element={<><Menu /> <AmbassadeurBoussole/></>}/>
            <Route path="/modifierSynthese/:id" element={<><Menu /> <EditSynthese/></>}/>
            <Route path="/modifierPlanAction/:id" element={<><Menu /> <EditPlanAction/></>}/>
            <Route path="/registerPlanAction/:id" element={<><Menu /> <FormPlanAction/></>}/>
            <Route path="/creationSociete" element={<><Menu /> <CreationSocietes /></>}/>
            <Route path="/societes" element={<><Menu /> <Societes /></>}/>
            <Route path="/societeEdit/:id" element={<><Menu /> <EditSocietes /></>}/>
            <Route path='/register' element={<><Menu /> <Register /></>}/>
            <Route path="/creationEquipes/:id" element={<><Menu /> <CreationEquipes /></>}/>
            <Route path="/creationBoussole/:id" element={<><Menu /> <CreationBoussole /></>}/>
            <Route path="/criteres/:id" element={<><Menu /> <Criteres /></>}/>
            <Route path="/creationCriteres/:id" element={<><Menu /> <CreationCriteres /></>}/>
            <Route path="/editCriteres/:id" element={<><Menu /> <EditCriteres /></>}/>
            <Route path="/users" element={<><Menu /> <Users /></>}/>
            <Route path="/userEdit/:id" element={<><Menu /> <EditUser /></>}/>
            
            <Route path="/equipeBoussole/:id" element={<><Menu /> <EquipeBoussole/></>}/>
          </Route>
        </Route>
      </Routes>
        
    </div>
  );
}