import React, { memo, useEffect, useState } from "react";
import MenuNonConnecté from "../Outils/MenuNonConnecté";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const RegisterUser = memo(() => {
  const navigate = useNavigate();
  const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");
  const [codeOk, setCodeOk] = useState(false);
  const [codeInput, setCodeInput] = useState("");
  const [codeInputError, setCodeInputError] = useState(false);
  const [equipe, setEquipe] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [pseudoError, setPseudoError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputPseudo = (event) => {
    setPseudo(event.target.value);
  };
  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleInputCodeEquipe = (event) => {
    setCodeInput(event.target.value);
  };

  const Validate = (pseudo,password) => {
    let pseudoError = "";
    let passwordError = "";

    // Validation pour le mot de passe
    if (password.length < 6) {
      passwordError = "Le mot de passe doit contenir au moins 6 caractères";
    }

    // Validation pour le pseudo
    if (pseudo.length < 4 ) {
      pseudoError = "Le pseudo doit contenir au moins 4 caractères";
    } else if (!/^[a-zA-Z0-9]+$/.test(pseudo)) {
      pseudoError =
        "Le pseudo doit contenir uniquement des lettres et des chiffres";
    }

    // Vérification des erreurs
    if (pseudoError || passwordError) {
      setPseudoError(pseudoError); // Remplacez cela par la fonction que vous utilisez pour définir l'erreur du pseudo
      setPasswordError(passwordError); // Remplacez cela par la fonction que vous utilisez pour définir l'erreur du mot de passe
      return false;
    }

    // Aucune erreur détectée, la validation réussit
    return true;
  };

  const FetchCode = async () => {
    const body = {
      codeEquipe: codeInput,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch("/api/equipes/getEquipeByCode", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.length === 1) {
          setEquipe(data);
          setCodeOk(true);
        } else {
          alert("Code incorrect");
        }
      });
  };
  const RegisterUserAdeo = () => {
    if (Validate(pseudo,password)) {
     
      const body = {
        pseudo: pseudo,
        password: password,
        equipe: equipe[0].ID_EQUIPE,
        nom: null,
        prenom: null,
        email: null,
        profil: 0,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/register/userAdeo", requestOptions)
        .then((res) => {
          if (res.status === 449) {
            alert("Ce pseudo est déjà utilisé");
          }

          if (res.status === 201) {
            alert("Votre compte a été créé avec succès");
            navigate("/userLogin");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {codeOk ? (
        <>
          <MenuNonConnecté />

          <div className="flex flex-col justify-center items-center h-screen">
            <h1
              className=" text-xl font-bold mb-5
            ">
              {" "}
              Veuillez creer votre compte Boussole{" "}
            </h1>
            <div className=" flex p-2 lg:p-5 mb-4 border-2 border-l-4 border-l-orange-incubate flex-col w-1/4">
              <label
                htmlFor="pseudo"
                className="block  md:text-lg font-semibold mb-2">
                Pseudo{" "}
              </label>
              <input
                type="texte"
                name="pseudo"
                placeholder="Votre Pseudo"
                onChange={handleInputPseudo}
                className="shadow appearance-none  w-full py-1 px-3 text-orange-incubate leading-tight focus:outline-none focus:shadow-outline mb-2"
              />
              {pseudoError ? (
                <div className="text-red-500">{pseudoError}</div>
              ) : null}
            </div>
            <div className=" flex p-2 lg:p-5 mb-4 border-2 border-l-4 border-l-orange-incubate flex-col w-1/4">
              <label
                htmlFor="password"
                className="block md:text-lg font-semibold mb-2">
                Mot de passe {""}
              </label>
              <div className="flex  space-x-2">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Mot de passe"
                  onChange={handleInputPassword}
                  className="shadow appearance-none  w-full py-1 px-3 text-orange-incubate leading-tight focus:outline-none focus:shadow-outline mb-2"
                />
                <button type="button" onClick={handleTogglePassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <p className="text-gray-600">6 caractères minimum</p>
              {passwordError ? (
                <div className="text-red-500">{passwordError}</div>
              ) : null}
            </div>
            <div className="flex justify-center mt-5">
              <button
                type="submit"
                onClick={RegisterUserAdeo}
                className="text-white py-2 px-5 mx-2 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate">
                <div className="flex flex-row items-center">
                  <p className="px-2 ">Inscription</p>
                </div>
              </button>
            </div>
            <div className=" flex justify-center items-end h-1/3 ">
              <img
                src="/images/IC_logo_couleurs_orange_nom_cote.png"
                alt="Logo Be Shake"
                className=" h-1/3"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <MenuNonConnecté />
          <div className="flex flex-col justify-center items-center h-screen">
            <div className=" flex p-2 lg:p-5 mb-4 border-2 border-l-4 border-l-orange-incubate flex-col ">
              <label
                htmlFor="password"
                className="block md:text-lg font-semibold mb-2">
                Veuillez entrer le code que le facilitateur vous a fourni{" "}
              </label>
              <input
                type="text"
                name="code"
                placeholder="Entrer le code"
                onChange={handleInputCodeEquipe}
                className="shadow appearance-none  w-full py-1 px-3 text-orange-incubate leading-tight focus:outline-none focus:shadow-outline mb-2"
              />
              {codeInputError ? (
                <div className="text-red-600">{codeInputError}</div>
              ) : null}
            </div>
            <div className="flex justify-center mt-5">
              <button
                type="button"
                onClick={FetchCode}
                className="text-white py-2 px-5 mx-2 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate">
                <div className="flex flex-row items-center">
                  <p className="px-2 ">Verifier le code</p>
                </div>
              </button>
            </div>
            <div className=" flex justify-center items-end h-1/3 ">
              <img
                src="/images/IC_logo_couleurs_orange_nom_cote.png"
                alt="Logo Be Shake"
                className=" h-1/3"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default RegisterUser;
