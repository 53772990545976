import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";

class EditMe extends Component {
    constructor(props){
        super(props);
        //Variables utilisées dans la page
        this.state = {
            //Fonctionnement de la page
            loadingIndivi : true,
            showModal : false,
            showModalAnnuler : false,
            modif : false,

            //Champs du formulaire
            prenom : '',
            nom : '',
            lastPassword : '',
            password : '',
            passwordBis : '', 

            //Erreur de champs
            prenomError : '',
            nomError : '',
            lastPasswordError : '',
            passwordError : '',
            passwordBisError : ''


        }
    }

    //Fonction de detection d'un changement dans un champs
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
        this.setState({
            modif : true,
          });
    }

    //Fonction de detection de modification du mdp
    handleChangeMDP = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
        
    }

    //Fonction de validation
    validate = () => {
        let prenomError = '';
        let nomError = '';

        //Validation prenom
        if(!this.state.prenom){
            prenomError = "Le prénom doit être renseigné"
        }
        if(this.state.prenom.length > 45){
            prenomError = "Le prenom est trop long"
        }

        //Validation nom
        if(!this.state.nom){
            nomError = "Le nom doit être renseigné"
        }
        if(this.state.nom.length > 45){
            nomError = "Le nom est trop long"
        }

        //Retour d'erreur
        if(prenomError || nomError){
            this.setState({prenomError, nomError});
            return false;   
        }else{
            this.setState({prenomError, nomError});
            return true; 
        }
    }

    //Validation du mot de passe conforme
    validatePassword = () => {
        let passwordError = '';
        let lastPasswordError = '';
        let passwordBisError = '';

        if(this.state.password.length < 6){
            passwordError = "Le mot de passe doit faire minimum 8 caractères";
        }
        if(this.state.password !== this.state.passwordBis){
            passwordBisError = "Les mots de passe doivent être identiques";
        }
        if(!this.state.lastPassword){
            lastPasswordError = "Veuillez renseigner votre ancien mot de passe";
        }

        //Retour d'erreur
        if(passwordError || passwordBisError || lastPasswordError){
            this.setState({passwordError, lastPasswordError, passwordBisError});
            return false;   
        }else{
            this.setState({passwordError, lastPasswordError, passwordBisError});
            return true; 
        }
    }

    //Fonction lancant la modification vers l'api
    modifier = (event) => {
        event.preventDefault();

        if(this.validate()){
            const body = {
                nom : this.state.nom,
                prenom : this.state.prenom,
            };
    
            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };
    
            fetch('/api/register/me/modify', requestOptions)
                .then( res => {
                    if(res.status === 201){
                        alert('Profil modifé avec succès !')
                        this.props.navigate('/dashboard');
                    }else {
                        alert("Erreur lors de la modification de votre profil ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                  console.error(err);
                })
        }
    }

    //Fonction de resset password vers l'api
    resetPassword = (event) => {
        event.preventDefault();
        if(this.validatePassword()){
            const body = {
                lastPassword : this.state.lastPassword,
                password : this.state.password
            };

            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };

            fetch('/api/register/me/modifyPassword', requestOptions)
                .then( res => {
                    if(res.status === 201){
                        this.setState({showModal : false});
                        alert('Mot de passe modifié avec succès !')
                    }else if(res.status === 401){
                        alert("Votre ancien mot de passe n'est pas le bon ...");
                    }else {
                        alert("Erreur lors de la modification du mot de passe ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                    console.error(err);
                })
        }
    }

    //Fonction de chargement des données à afficher au chargement de la page
    componentDidMount(){
        //Récupération de l'individu à modifier
        const requestOptions = {
            method : 'GET', 
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        fetch('/api/register/me', requestOptions)
            .then(res => {
                return res.json();  
            }).then(data => {
                this.setState ({
                    nom : data.NOM,
                    prenom : data.PRENOM,
                    loadingIndivi : false
                });
            }).catch(error => {
                console.log(error);
            });
    }
    
    //Fonction de rendu 
    render(){
        if (this.state.loadingIndivi){
            return <h1> </h1>
        }else{
            return (
                <div className='flex flex-col items-center justify-center h-full pt-32'>
                    <h1 className="text-orange-incubate text-3xl sm:text-2xl">Mon compte</h1>
                    <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
                        <div className="md:mb-4">
                            <label htmlFor='nom' className="block font-semibold">Nom</label>
                            <input type='text' name='nom' placeholder='Nom' value={this.state.nom} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"/>
                            {this.state.nomError ? <div className="text-red-600">{this.state.nomError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='prenom' className="block text-l font-semibold mb-2">Prenom</label>
                            <input type='text' name='prenom' placeholder='Prenom' value={this.state.prenom} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"/>
                            {this.state.prenomError ? <div className="text-red-600">{this.state.prenomError}</div> : null }
                        </div>
                        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2 sm:text-sm" onClick={this.modifier}>Mettre à jour</button>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2 sm:text-sm" onClick={() => {this.setState({showModal:true})}}>Modifier mon mot de passe</button>
                            {this.state.modif ? ( <>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2 sm:text-sm"  onClick={() => {this.setState({showModalAnnuler:true})}} >Annuler</button>
                            </> ): <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2 sm:text-sm"  onClick={()=>{this.props.navigate("/dashboard")}}>Annuler</button> }
                        </div>
                    </form>

                    {this.state.showModal ? (
                        <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold sm:text-sm">Modification de votre mot de passe</h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                <div className="md:mb-4 sm:text-sm">
                                    <label htmlFor='lastPassword' className="block font-semibold">Ancien mot de passe</label>
                                    <input type='password' name='lastPassword' placeholder='Ancien mot de passe' value={this.state.lastPassword} onChange={this.handleChangeMDP} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                                    {this.state.lastPasswordError ? <div className="text-red-600">{this.state.lastPasswordError}</div> : null }
                                </div>
                                <div className="md:mb-4 sm:text-sm">
                                    <label htmlFor='password' className="block font-semibold">Nouveau mot de passe</label>
                                    <input type='password' name='password' placeholder='Nouveau mot de passe' value={this.state.password} onChange={this.handleChangeMDP} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                                    {this.state.passwordError ? <div className="text-red-600">{this.state.passwordError}</div> : null }
                                </div>
                                <div className="md:mb-4 sm:text-sm">
                                    <label htmlFor='passwordBis' className="block font-semibold">Nouveau mot de passe</label>
                                    <input type='password' name='passwordBis' placeholder='Nouveau mot de passe' value={this.state.passwordBis} onChange={this.handleChangeMDP} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                                    {this.state.passwordBisError ? <div className="text-red-600">{this.state.passwordBisError}</div> : null }
                                </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b ">
                                <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 sm:text-sm" type="button" onClick={() => this.setState({showModal:false})}>Annuler</button>
                                <button
                                    className="bg-orange-incubate text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 sm:text-sm" type="button" onClick={this.resetPassword} >Modifier</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}


                    {/*Modal annuler*/}

            {this.state.showModalAnnuler ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">ATTENTION</h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Vos modifications ne seront pas sauvegardées !
                            <br />Etes vous sûr de vouloir continuer ?
                        </p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalAnnuler:false})}>Non</button>
                        <button type='button' className="bg-orange-incubate text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={()=>{this.props.navigate("/dashboard")}}>Oui</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
                </div>
            );
        }
    }
}

//Exports
export default withRouter(EditMe);