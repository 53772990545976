import React, { memo, useEffect } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditSynthese = memo(() => {
  const idBoussole = useParams().id;
  const navigate = useNavigate();
  const id_boussole = useParams().id;
  const [synthese_boussole, setSynthese_boussole] = useState();
  const [synthese, setSynthese] = useState();
  const [libelleError, setLibelleError] = useState();

  const FetchSynthese = async (idSynthese) => {
    const body = {
      idBoussole: idBoussole,
    };

    const get = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/syntheseBoussole/getSyntheseByBoussole", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        
        setSynthese(data.synthese);
        setSynthese_boussole(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChangeSynthese = (event) => {
    setSynthese(event.target.value);
  };

  const validate = () => {
    let Error = "";

    //Validation libelle
    if (!synthese_boussole) {
      Error = "Selectionner une reponse";
      alert("veuillez entrer la synthese svp");
    }

    //Retour d'erreur

    if (Error) {
      setLibelleError(Error);
      return false;
    } else {
      setLibelleError(Error);
      return true;
    }
  };
  const UpdateSynthese = () => {
    if (validate()) {
      const body = {
        synthese: synthese,
        id_synthese: synthese_boussole.id_synthese,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/syntheseBoussole/modify", requestOptions)
        .then((res) => {
          if (res.status === 200) {
            alert("la synthese a été mis a jour avec succès !");
            navigate(`/resultatBoussole/${id_boussole}`);
          } else {
            alert("Erreur lors de la modification de la synthese");
            navigate(`/resultatBoussole/${id_boussole}`);

            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    FetchSynthese(idBoussole);
  }, [idBoussole]);
  return (
    <div>
      <>
        <div>
          <div className=" flex-col justify-center">
            <h2 className="flex justify-center mb-3 text-xl text-orange-incubate">
              Synthese Boussole
            </h2>
            <div className="flex justify-center">
              <div className="w-1/3 mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <div className="  bg-white rounded-t-lg dark:bg-gray-800 ">
                  <textarea
                    onChange={handleInputChangeSynthese}
                    rows="15
                  "
                    className="w-full px-4 py-2  text-sm text-gray-900 bg-white  dark:bg-gray-800  dark:text-white dark:placeholder-gray-400"
                    placeholder="Merci d'entrer la synthese ...."
                    value={synthese}
                    required></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={UpdateSynthese}
              className="border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg  text-white mt-4">
              Valider
            </button>
          </div>
        </div>
      </>
    </div>
  );
});

export default EditSynthese;
