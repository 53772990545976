import React, { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ResultatDomaine = memo(() => {
  const [tableDomaine, setTableDomaine] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const fetchEquipe = async () => {
    //Body de la requete
    const body = {
      idSociete: params.id,
    };

    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    //Récupération des equipes
    fetch("/api/equipes/getAllEquipes", getByIdOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTableDomaine(data.filter((data) => data.ID_PARENT === null));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchEquipe();
  }, []);

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg  w-1/2 mx-auto mt-10 mb-10 ">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white uppercase bg-orange-incubate dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                Domaines
              </th>

              <th scope="col" className="px-3 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableDomaine.map((domaine) => (
              <tr className="bg-white border-b  " key={domaine.ID_EQUIPE}>
                <th
                  scope="row"
                  className="px-3 py-2 font-medium text-gray-900 whitespace-nowrap "
                >
                  {domaine.LIBELLE_EQUIPE}
                </th>
                <td className="px-3 py-2">
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/resultatDomaine/${domaine.ID_EQUIPE}/${params.id}`);
                    }}
                    className="font-medium text-orange-incubate dark:text-blue-500 hover:underline"
                  >
                    Résultat
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default ResultatDomaine;
