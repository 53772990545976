import React, { memo, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

// Plugin personnalisé pour colorier le fond du graphique et dessiner les axes
const canvasBackgroundColor = {
  id: "canvasBackgroundColor",
  beforeDraw: (chart) => {
    const { ctx, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart;

    // Définir les couleurs des sections
    const colors = [
      { yMin: -6, yMax: 0, color: "rgba(255,26,104,0.2)" }, // Rouge en bas
      { yMin: 0, yMax: 6, color: "rgba(147,250,165,0.2)" },  // vert au milieu bas
     
    ];

    // Colorier les différentes parties du graphique
    colors.forEach(({ yMin, yMax, color }) => {
      drawBackground(ctx, left, right, y.getPixelForValue(yMax), y.getPixelForValue(yMin), color);
    });

    // Dessiner les axes
    drawAxis(ctx, left, right, y.getPixelForValue(0)); // Axe x
    drawAxis(ctx, top, bottom, x.getPixelForValue(0), true); // Axe y

    function drawBackground(ctx, left, right, top, bottom, color) {
      ctx.save();
      ctx.fillStyle = color;
      ctx.fillRect(left, top, right - left, bottom - top);
      ctx.restore();
    }

    function drawAxis(ctx, start, end, pos, vertical = false) {
      ctx.save();
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 2;
      ctx.beginPath();
      if (vertical) {
        ctx.moveTo(pos, start);
        ctx.lineTo(pos, end);
      } else {
        ctx.moveTo(start, pos);
        ctx.lineTo(end, pos);
      }
      ctx.stroke();
      ctx.restore();
    }
  },
};

ChartJS.register(
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  zoomPlugin,
  canvasBackgroundColor
);

const BubbleChart = memo((props) => {
  const test = props.data;

  useEffect(() => {
    ChartJS.register(canvasBackgroundColor);
  }, []);

  function getRandomColor() { // fonction pour avoir des couleurs aléatoires
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const data = {
    datasets: test.map((item) => ({
      label: item.libelle,
      data: [{
        x: item.moyenneQ1,
        y: item.moyenneQ2,
        r: 15,
      }],
      backgroundColor: getRandomColor(),
    })),
  };

  let delayed;

  const options = {
    responsive: true,
    scales: {
      y: {
        min: -6,
        max: 6,
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
      x: {
        min: -6,
        max: 6,
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Resultat Boussole",
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: "xy",
      //   },
      // },
      canvasBackgroundColor: {
        id: "canvasBackgroundColor"
      }
    },
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && !delayed) {
          delay = context.dataIndex * 1000 + context.datasetIndex * 400;
        }
        return delay;
      },
    },
  };

  return (
    <Bubble
      className="border border-orange-incubate rounded-lg shadow-xl p-5"
      options={options}
      data={data}
    />
  );
});

export default BubbleChart;
