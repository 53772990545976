import React, { memo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";

const PlanActionEquipe = memo((props) => {
  const navigate = useNavigate();
  const [planActions, setPlanActions] = useState([]);
  const [idEquipe, setIdEquipe] = useState(null);
  const isAdmin = props.isAdmin;
 
  const fecthPlanAction = async (idEquipe) => {
    const requestOptions_2 = {
      method: "POST",
      body: JSON.stringify({ id_equipe: idEquipe }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch("/api/planEquipe/getPlanByEquipe", requestOptions_2)
      .then((res) => res.json())
      .then((data) => {
        setPlanActions(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const fetchEquipeAndPlan = async () => {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ idBoussole: props.idBoussole }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      await fetch("/api/equipes/getEquipeByBoussole", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setIdEquipe(data.ID_EQUIPE);
          return data.ID_EQUIPE;
        })
        .then((idEquipe) => {
          if (idEquipe) {
            fecthPlanAction(idEquipe);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchEquipeAndPlan();
  }, [props.idBoussole]);

  if (!planActions.length > 0) {
    return (
      <>
        <h1 className="text-orange-incubate text-2xl text-center mt-5">
          Plan d'action
        </h1>
        <h2 className="text-center mt-20 text-xl">
          Ajouter un plan d'action svp{" "}
        </h2>
      </>
    );
  } else {
    return (
      <div className="w-full ">
        <h1 className="text-orange-incubate text-2xl text-center mt-5">
          Plan d'action
        </h1>
        <div className="flex justify-center mt-5 ">
          <table className="items-center bg-transparent w-full border-collapse border border-black ">
            <thead>
              <tr>
                <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase   whitespace-nowrap  text-center  ">
                  Equipe
                </td>
                <td className="  bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase border-l-0  whitespace-nowrap text-center ">
                  Objectif
                </td>
                <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                  Action
                </td>
                <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                  Lead ?
                </td>
                <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                  Date
                </td>
                {isAdmin ? (
                  <>
                    <td className=" bg-orange-50 text-orange-incubate align-middle border border-solid border-orange-incubate p-3 text-xs uppercase  border-l-0  whitespace-nowrap  text-center">
                      Modifier
                    </td>
                  </>
                ) : null}
              </tr>
            </thead>

            <tbody>
              {planActions.length > 0
                ? planActions.map((planAction, index) => (
                    <tr key={index}>
                      <td className="p-1 border border-orange-incubate text-center bg-orange-100 break-all text-sm">
                        {planAction.libelle_equipe}
                      </td>
                      <td className="p-1 border border-orange-incubate text-center break-all text-sm">
                        {planAction.objectif}
                      </td>
                      <td className="p-1 border border-orange-incubate text-center break-all text-sm">
                        {planAction.action}
                      </td>
                      <td className="p-1 border border-orange-incubate text-center break-all text-sm">
                        {planAction.lead}
                      </td>
                      <td className="p-1 border border-orange-incubate text-center bg-green-100 break-all text-sm">
                        {planAction.DATE}
                      </td>
                      {isAdmin ? (
                        <>
                          {" "}
                          <td className="p-1 border border-orange-incubate text-center ">
                            {" "}
                            <button
                              onClick={() => {
                                navigate(
                                  `/modifierPlanAction/${planAction.id_plan}`
                                );
                              }}>
                              <AiOutlineEdit />
                            </button>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))
                : "null"}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
});

export default PlanActionEquipe;
