import React, { memo, useState, useEffect } from "react";
import Avatar from "../../assets/images/avatar.png";

const Commentaire = memo((props) => {
  const tabProps = props.dataCommentaire;
  const [tabCommentaire, setTabCommentaire] = useState([]);
  const [limited, setLimited] = useState(true);

  const AllCommentaire = (tabProps) => {
    for (let i = 0; i < tabProps.length; i++) {
      tabCommentaire.push(tabProps[i].commentaire);
    }

    return tabCommentaire;
  };
  useEffect(() => {
    if (limited) {
      AllCommentaire(tabProps);
      setLimited(false);
    }
  });

  return (
    <>
      {tabCommentaire.map((commentaire, index) => (
        <div
          key={index}
          className=" mb-5 flex  items-center w-96 px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3"
        >
          <img
            src={Avatar}
            alt="avatar"
            className="hidden object-cover w-14 h-14 mx-4 rounded-full sm:block"
          />

          <div>
            <h3 className="text-lg font-bold text-orange-incubate sm:text-xl md:text-2xl">
              Anonyme
            </h3>

            <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal break-all">
              {commentaire}
            </p>
          </div>
        </div>
      ))}
      
    </>
  );
});

export default Commentaire;
