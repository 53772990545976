import React, { memo, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LogoAdeo from "../../assets/images/adeo.png";

const FormPlanAction = memo(() => {
  const params = useParams();
  const location = useLocation();
  // Nous avons ensuite utilisé l'objet location pour récupérer la chaîne de requête de l'URL actuelle, qui contient les paramètres d'URL. Nous avons ensuite utilisé l'objet URLSearchParams pour extraire la valeur de autreParam de la chaîne de requête.
  const autreParam = new URLSearchParams(location.search).get("autreParam");
 

  const navigate = useNavigate();
  const [objectif, setObjectif] = useState([]);
  const [action, setAction] = useState([]);
  const [lead, setLead] = useState([]);
  const [libelleError, setLibelleError] = useState();

  const handleInputChangeObjectif = (event) => {
    setObjectif(event.target.value);
  };

  const handleInputChangeAction = (event) => {
    setAction(event.target.value);
  };
  const handleInputChangeLead = (event) => {
    setLead(event.target.value);
  };

  const validate = () => {
    let Error = "";

    //Validation libelle
    if (!objectif) {
      Error = "Selectionner une reponse";
      alert("veuillez entrer un objectif svp");
    } else if (!action) {
      Error = "Selectionner une reponse";
      alert("veuillez entrer une action svp");
    } else if (!lead) {
      Error = "Selectionner une reponse";
      alert("veuillez entrerun Lead svp");
    }

    //Retour d'erreur

    if (Error) {
      setLibelleError(Error);
      return false;
    } else {
      setLibelleError(Error);
      return true;
    }
  };

  const RegisterPLanAction = async () => {
    if (validate()) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch("/api/register/me", requestOptions);
        const data = await response.json();
        const id_individu = data.ID_INDIVIDU;
      

        const getByIdOptions = {
          method: "POST",
          body: JSON.stringify({
            id_equipe: params.id,
            objectif: objectif,
            action: action,
            lead: lead,
            id_individu: id_individu,
            id_boussole: autreParam,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        };

        fetch("/api/planEquipe", getByIdOptions)
          .then((res) => {
            if (res.status === 201) {
              alert("le plan d'action a été rajouté avec succès !");
              navigate(`/resultatBoussole/${autreParam}`);
            } else {
              alert("Veuillez remplir tous les champs svp ");

              throw new Error(res.error);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>

    <div className="flex justify-center"><img src={LogoAdeo} alt="logoAdeo" className="w-40 h-20 mt-5 " /></div>
      <div className="text-center">
        
        <h2 className="text-xl text-orange-incubate mb-3">
          Ajouter le plan d'action
        </h2>
      </div>

      <div className="flex justify-center space-x-10 mt-10">
        <div className="flex-col">
          <h1 className="text-center text-orange-incubate">Objectif</h1>
          <div className="flex justify-center">
            <div className="mb-4 border border-gray-200 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="bg-white rounded-t-lg dark:bg-gray-800">
                <textarea
                  onChange={handleInputChangeObjectif}
                  rows="10"
                  className="w-full px-4 py-2 text-sm text-gray-900 bg-white dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer les objectifs ..."
                  required></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col">
          <h1 className="text-center text-orange-incubate">Action</h1>
          <div className="flex justify-center">
            <div className="mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="bg-white rounded-t-lg dark:bg-gray-800">
                <textarea
                  onChange={handleInputChangeAction}
                  rows="10"
                  className="w-full px-4 py-2 text-sm text-gray-900 bg-white dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer les actions ..."
                  required></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col">
          <h1 className="text-center text-orange-incubate">Lead</h1>
          <div className="flex justify-center">
            <div className="mb-4 border border-gray-300 rounded-xl bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
              <div className="bg-white rounded-t-lg dark:bg-gray-800">
                <textarea
                  onChange={handleInputChangeLead}
                  rows="10"
                  className="w-full px-4 py-2 text-sm text-gray-900 bg-white dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
                  placeholder="Merci d'entrer le Lead ..."
                  required></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={RegisterPLanAction}
          className="border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg text-white mt-4">
          Valider
        </button>
      </div>
    </>
  );
});

export default FormPlanAction;
