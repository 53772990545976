import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { BiRightArrowAlt } from "react-icons/bi";
import Fleche from "../../assets/images/fleche.png";
import AdeoLogo from "../../assets/images/adeo.png";

class ReponseBoussole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Champs du formulaire
      idSociete: " ",
      reponse_1: null,
      reponse_2: null,
      commentaire: "",
      //Champs de l'api
      idCritere: " ",
      libelle: " ",
      libelle_ff_vert: " ",
      libelle_ff_rouge: " ",
      libelle_or_vert: " ",
      libelle_or_rouge: " ",
      description: " ",
      image: " ",
      totalResultat: 0,
      totalCritere: 0,
      idBoussoleIndividu: " ",
      Acces: false,
      statut: "",

      //Erreur de champs
      loadingCritere: true,
    };
  }

  handleOptionChangeReponse_1 = (changeEvent) => {
    this.setState({
      reponse_1: changeEvent.target.value,
    });
  };

  handleOptionChangeReponse_2 = (changeEvent) => {
    this.setState({
      reponse_2: changeEvent.target.value,
    });
  };
  handleOptionChangeCommentaire = (changeEvent) => {
    this.setState({
      commentaire: changeEvent.target.value,
    });
  };

  validate() {
    let Error = "";

    //Validation libelle
    if (!this.state.reponse_1) {
      Error = "Selectionner une reponse";
      alert("veuillez svp repondre aux question");
    }
    if (!this.state.reponse_2) {
      Error = "Selectionner une reponse";
      alert("veuillez svp repondre aux question");
    }
    if (!this.state.commentaire) {
      Error = "Selectionner une reponse";
      alert("veuillez entrer un commentaire svp");
    }

    //Retour d'erreur

    if (Error) {
      this.setState({ libelleError: Error });
      return false;
    } else {
      this.setState({ libelleError: Error });
      return true;
    }
  }

  //Fonction appalant l'api pour réaliser la création
  Suivant = (event) => {
    event.preventDefault();

    if (this.validate()) {
      const body = {
        idBoussoleIndividu: this.state.idBoussoleIndividu,
        idCritere: this.state.idCritere,
        question_1: this.state.reponse_1,
        question_2: this.state.reponse_2,
        commentaire: this.state.commentaire,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/boussole/resultatBoussole", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            if (
              this.state.totalCritere === this.state.totalResultat + 1
            ) {
              this.Terminer();
            } else {
              this.setState({
                reponse_1: null,
                reponse_2: null,
                commentaire: null,
              });
              this.NextCritere();
            }
          } else {
            alert("Erreur ...");
            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  Terminer = () => {
    const body = {
      idBoussole: this.props.params.id,
      statut: "Terminé",
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/boussole/modifStatut", requestOptions)
      .then((res) => {
        if (res.status === 200) {
          alert("Boussole Terminé !");
          // this.props.navigate(`/resultatBoussole/${this.props.params.id}`);
           this.props.navigate(
             `/boussoleCommentaire/${this.state.idBoussoleIndividu}`
          );
        } else {
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  NextCritere = () => {
    const body = {
      idSociete: this.state.idSociete,
      idBoussole: this.props.params.id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/boussole/getNextQuestion", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          idCritere: data.id_critere_boussole,
          libelle: data.libelle,
          libelle_ff_vert: data.libelle_ff_vert,
          libelle_ff_rouge: data.libelle_ff_rouge,
          libelle_or_vert: data.libelle_or_vert,
          libelle_or_rouge: data.libelle_or_rouge,
          description: data.description,
          image: data.Logo,
          totalResultat: data.totalResultat,
          totalCritere: data.totalCritere,
          idBoussoleIndividu: data.idBoussoleIndividu,
          reponse_1: null,
          reponse_2: null,
          commentaire: "",
          loadingCritere: false,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fonction au chargement de la page pour récupérer les informations d'affichage
  componentDidMount() {
    //Récupération de id Societe
    const bodyIdSociete = {
      idBoussole: this.props.params.id,
    };

    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify(bodyIdSociete),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/boussole/getStatut", getByIdOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          statut: data.statut,
        });

        return fetch("/api/societes/getSocieteByBoussole", getByIdOptions);
      })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          idSociete: data.ID_SOCIETE,
        });

        //Récupération des criteres
        const body = {
          idSociete: this.state.idSociete,
          idBoussole: this.props.params.id,
        };

        const getByIdOptions2 = {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        };

        return fetch("/api/boussole/getNextQuestion", getByIdOptions2);
      })
      .then((res) => {
        if (res.status === 401) {
          this.setState({ Acces: true });
        }
        return res.json();
      })
      .then((data) => {
        if (data.statut === "Terminé") {
          this.Terminer();
        }
        this.setState({
          idCritere: data.id_critere_boussole,
          libelle: data.libelle,
          libelle_ff_vert: data.libelle_ff_vert,
          libelle_ff_rouge: data.libelle_ff_rouge,
          libelle_or_vert: data.libelle_or_vert,
          libelle_or_rouge: data.libelle_or_rouge,
          description: data.description,
          image: data.Logo,
          totalResultat: data.totalResultat,
          totalCritere: data.totalCritere,
          idBoussoleIndividu: data.idBoussoleIndividu,
          reponse_1: null,
          reponse_2: null,
          commentaire: "",
          loadingCritere: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Fonction de rendu
  render() {
    if (this.state.Acces === true) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          Unauthorized
        </h1>
      );
    } else if (this.state.statut === "Terminé") {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          Boussole Terminé
        </h1>
      );
    } else if (this.state.loadingCritere) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          loading
        </h1>
      );
    } else {
      return (
        <>
          <div className="flex justify-center ">
            <img src={AdeoLogo} alt="" className="h-24 " />
          </div>
          <h1 className="flex justify-center  text-2xl text-orange-incubate font-bold">
            Boussole {new Date().getFullYear()}
          </h1>

          <div className="flex justify-center space-x-80 ">
            <div className="w-1/4 bg-stone-50 rounded-xl  shadow-xl p-2 ">
              <h2 className="text-center p-1 mt-2 font-semibold ">
                {this.state.libelle}
              </h2>

              <div className="flex justify-center w-full p-4">
                <div>
                  <span className="font-semibold ">Description:</span>
                  <br></br>
                  <p className="mt-1 ml-2 whitespace-pre-line  ">
                    {this.state.description}
                  </p>
                </div>
              </div>

              <img
                src={this.state.image}
                alt=""
                className=" mx-auto mt-2 w-11/12 rounded-3xl p-1 mb-2"
              />
            </div>
            <div>
              {/* formulaire debut */}

              <div className="flex-col space-y-6 justify-center mt-2 mb-5 ">
                <h4 className="font-semibold mt-2 ">
                  {this.state.libelle_ff_vert}
                </h4>
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  className=" flex  justify-center space-x-4 ">
                  <div className="flex flex-col">
                    <label>-5 </label>

                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === -5}
                      onChange={this.handleOptionChangeReponse_1}
                      value={-5}
                      className="accent-red-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300        focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label>-4</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === -4}
                      onChange={this.handleOptionChangeReponse_1}
                      value={-4}
                      className="accent-red-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="">-3</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === -3}
                      onChange={this.handleOptionChangeReponse_1}
                      value={-3}
                      className="accent-red-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="">-2</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === -2}
                      onChange={this.handleOptionChangeReponse_1}
                      value={-2}
                      className="accent-orange-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 "
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="">-1</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === -1}
                      onChange={this.handleOptionChangeReponse_1}
                      value={-1}
                      className="accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="ml-1">0</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === 0}
                      onChange={this.handleOptionChangeReponse_1}
                      value={0}
                      className=" accent-orange-500 focus:accent-orange-500 mb-1  w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">1</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === 1}
                      onChange={this.handleOptionChangeReponse_1}
                      value={1}
                      className="accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">2</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === 2}
                      onChange={this.handleOptionChangeReponse_1}
                      value={2}
                      className=" accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="ml-1">3</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === 3}
                      onChange={this.handleOptionChangeReponse_1}
                      value={3}
                      className=" accent-green-500 mr-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">4</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === 4}
                      onChange={this.handleOptionChangeReponse_1}
                      value={4}
                      className="accent-green-500 mr-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="ml-1">5</label>
                    <input
                      type="radio"
                      name="reponse_1"
                      chechek={this.state.reponse_1 === 5}
                      onChange={this.handleOptionChangeReponse_1}
                      value={5}
                      className="accent-green-500 mr-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                </div>
                {/* fleche */}
                <div className="flex justify-center ">
                  <img
                    src={Fleche}
                    alt="fleche de graduation"
                    className="w-96"
                  />
                </div>
                {/* fleche */}
                <h4 className="font-semibold ">{this.state.libelle_or_vert}</h4>
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  className=" flex justify-center space-x-4">
                  <div className="flex flex-col">
                    <label>-5</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === -5}
                      onChange={this.handleOptionChangeReponse_2}
                      value={-5}
                      className="accent-red-500 mb-1 w-4 h-4  bg-gray-100 border-gray-300 focus:ring-red-500   "
                    />
                  </div>

                  <div className="flex flex-col">
                    <label>-4</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === -4}
                      onChange={this.handleOptionChangeReponse_2}
                      value={-4}
                      className="accent-red-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="">-3</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === -3}
                      onChange={this.handleOptionChangeReponse_2}
                      value={-3}
                      className="accent-red-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="">-2</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === -2}
                      onChange={this.handleOptionChangeReponse_2}
                      value={-2}
                      className="accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="">-1</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === -1}
                      onChange={this.handleOptionChangeReponse_2}
                      value={-1}
                      className="accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="ml-1">0</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === 0}
                      onChange={this.handleOptionChangeReponse_2}
                      value={0}
                      className="accent-orange-500 mb-1  w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">1</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === 1}
                      onChange={this.handleOptionChangeReponse_2}
                      value={1}
                      className="accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">2</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === 2}
                      onChange={this.handleOptionChangeReponse_2}
                      value={2}
                      className="accent-orange-500 mb-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-orange-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="ml-1">3</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === 3}
                      onChange={this.handleOptionChangeReponse_2}
                      value={3}
                      className="accent-green-500 mr-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="ml-1">4</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === 4}
                      onChange={this.handleOptionChangeReponse_2}
                      value={4}
                      className="accent-green-500 mr-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="ml-1">5</label>
                    <input
                      type="radio"
                      name="reponse_2"
                      chechek={this.state.reponse_2 === 5}
                      onChange={this.handleOptionChangeReponse_2}
                      value={5}
                      className="accent-green-500 mr-1 w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  {/* formulaire fin */}
                </div>

                {/* fleche */}
                <div className="flex justify-center ">
                  <img
                    src={Fleche}
                    alt="fleche de graduation"
                    className="w-96 "
                  />
                </div>
                {/* <div>
                  <h2 className="text-orange-500">commentaire</h2>
                  <textarea>

                  </textarea>
                </div> */}
                <div className=" bg-white rounded-t-lg dark:bg-gray-800  ">
                  <h2 className="text-orange-incubate mb-1">Commentaire</h2>
                  <textarea
                    onChange={this.handleOptionChangeCommentaire}
                    rows="2"
                    className="w-full  py-2 border-gray-300 rounded-lg px-2 text-sm text-gray-900 bg-white border dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    placeholder="Merci d'entrer un commentaire...."
                    required></textarea>
                </div>
                <div className="flex justify-end ">
                  <button
                    onClick={this.Suivant}
                    className=" flex px-4 py-2  justify-center border border-orange-incubate bg-orange-incubate w-52 p-2 rounded-lg  text-white mt-1">
                    Suivant
                    <BiRightArrowAlt size="1.6em" className=" text-white" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="flex justify-center mb-8 font-bold">
              {this.state.totalResultat + 1}/{this.state.totalCritere}
            </p>
          </div>
        </>
      );
    }
  }
}

//Export
export default withRouter(ReponseBoussole);
